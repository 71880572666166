import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/integrations';
import Icon from 'components/base/icon';
import Modal from 'utils/modal';

class ListItem extends React.Component {
  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick(this.props.integration.id);
  };

  getProvider(t) {
    switch (t) {
      case 'line_works':
        return 'LINE WORKS';
      case 'web_client':
        return 'Webクライアント';
    }
  }

  onDeleteClick = (e) => {
    e.preventDefault();
    Modal.confirm(
      '連携サービスを削除します。この操作は元に戻せません。よろしいですか？',
      null,
      () => {
        this.props.onDelete(this.props.integration);
      },
    );
  };

  render() {
    var integration = this.props.integration;
    return (
      <li className="list-group-item list-group-item-action">
        <div className="row">
          <div className="col">
            <a href="#!" onClick={this.handleClick}>
              {this.getProvider(integration.provider)}
            </a>
          </div>
          <div className="col">
            <a className="ml-4" href="#!" onClick={this.handleClick}>
              <Icon name="edit" />
              編集
            </a>
            <a className="ml-2" href="#!" onClick={this.onDeleteClick}>
              <Icon name="delete" />
              削除
            </a>
          </div>
        </div>
      </li>
    );
  }
}

class Integrations extends React.Component {
  constructor(props) {
    super(props);
  }

  newIntegration = (e) => {
    e.preventDefault();
    this.props.actions.newIntegration(true);
  };

  setIntegration = (id) => {
    this.props.actions.setIntegration(id, true);
  };

  deleteIntegration = (id) => {
    this.props.actions.deleteIntegration(id);
  };

  render() {
    return (
      <div className={styles.mainBody}>
        <h3>連携サービス</h3>
        <div>
          <a href="#!" onClick={this.newIntegration}>
            <Icon name="add_circle_outline" />
            連携サービスを追加する
          </a>
        </div>
        {this.props.integrations.length == 0 ? (
          <div className="mt-4 mb-4">連携サービスはありません</div>
        ) : (
          <ul className="list-group mt-4 mb-4">
            {this.props.integrations.map((integration) => {
              return (
                <ListItem
                  key={integration.id}
                  integration={integration}
                  onClick={this.setIntegration}
                  onDelete={this.deleteIntegration}
                />
              );
            })}
          </ul>
        )}
      </div>
    );
  }
  componentDidMount() {}
}

Integrations.propTypes = {
  integrations: PropTypes.array.isRequired,
};

Integrations.defaultProps = {};

export default Integrations;
