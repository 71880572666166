import React, { useEffect, useState } from 'react';

export default function DateTimeInput({
  value,
  onChange,
  className,
  disabled,
  readOnly,
  required,
}) {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  useEffect(() => {
    if (value) {
      setDate(value.split(' ')[0]);
      setTime(value.split(' ')[1]);
    }
  }, [value]);

  useEffect(() => {
    if (date && time) {
      onChange(`${date} ${time}`);
    } else {
      onChange('');
    }
  }, [date, time]);
  return (
    <div style={{ display: 'flex', columnGap: '8px' }}>
      <div>
        <input
          type="date"
          className={className}
          disabled={disabled}
          readOnly={readOnly}
          value={date}
          onChange={(e) => {
            if (readOnly) return;
            setDate(e.target.value);
          }}
          required={required}
        />
      </div>
      <div>
        <input
          type="time"
          className={className}
          disabled={disabled}
          readOnly={readOnly}
          value={time}
          onChange={(e) => {
            if (readOnly) return;
            setTime(e.target.value);
          }}
          required={required}
        />
      </div>
    </div>
  );
}
