import Xhr from 'utils/xhr.js';
import Modal from 'utils/modal';
import * as types from './action_types.js';
import { id } from './edit';

export const receiveScenario = (scenario) => {
  return {
    type: types.RECEIVE_SCENARIO,
    scenario: scenario,
  };
};

export const receiveScenarios = (scenarios) => {
  return {
    type: types.RECEIVE_SCENARIOS,
    scenarios: scenarios,
  };
};

export const setPoppedState = (popedState) => {
  return (dispatch, getState) => {
    if (popedState == 'new') {
      dispatch(newScenario(false));
    } else if (popedState) {
      dispatch(setScenario(popedState, false));
    } else {
      dispatch(fetchScenarios(false));
    }
  };
};

const setHistory = (state, scenarioId) => {
  if (window.history && window.history.pushState) {
    var url = `/agents/${state.agent.id}/scenarios`;
    if (scenarioId == 'new') {
      url = url + '/new';
    } else if (scenarioId) {
      url = url + `/${scenarioId}/edit`;
    }
    window.history.pushState(scenarioId, '', url);
  }
};

export const newScenario = (pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    if (pushState) {
      setHistory(state, 'new');
    }
    dispatch(
      receiveScenario({
        id: 0,
        name: '',
        app_url: '',
        scenario_type: 'general_search',
        input_fields: [],
        queries: [],
        select_fields: [],
        sort_fields: [],
        update_fields: [],
      }),
    );
  };
};

export const duplicateScenario = (scenarioId, pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/scenarios/${scenarioId}`,
      {},
      (data) => {
        if (pushState) {
          setHistory(state, 'new');
        }
        const scenario = data.scenario;
        scenario.id = 0;
        scenario.name = `${scenario.name}のコピー`;
        const childFields = [
          'input_fields',
          'queries',
          'select_fields',
          'sort_fields',
          'update_fields',
        ];
        childFields.forEach((field) => {
          scenario[field] = scenario[field].map((f) => {
            f.id = id();
            return f;
          });
        });
        dispatch(receiveScenario(scenario));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
};

export const setScenario = (scenarioId, pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/scenarios/${scenarioId}`,
      {},
      (data) => {
        if (pushState) {
          setHistory(state, scenarioId);
        }
        dispatch(receiveScenario(data.scenario));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
};

export const fetchScenarios = (pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/scenarios`,
      {},
      (data) => {
        if (pushState) {
          setHistory(state, null);
        }
        dispatch(receiveScenarios(data.scenarios));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
};

export const saveScenario = (scenario) => {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = `agents/${state.agent.id}/scenarios`;
    if (scenario.id) {
      method = 'PATCH';
      url += `/${scenario.id}`;
    } else {
      method = 'POST';
    }
    Xhr.execute(
      method,
      url,
      scenario,
      (data) => {
        Modal.toast(
          'alert',
          '設定が保存されました。チャットボットへの反映を行ってください。',
        );
        dispatch(fetchScenarios(true));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr, 'scenario'), xhr.responseJSON));
      },
    );
  };
};

export const deleteScenario = (scenario) => {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = `agents/${state.agent.id}/scenarios/${scenario.id}`;
    method = 'DELETE';
    Xhr.execute(
      method,
      url,
      {},
      (data) => {
        dispatch(fetchScenarios(false));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr, 'scenario'), xhr.responseJSON));
      },
    );
  };
};

export const clearScenario = (pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    if (pushState) {
      setHistory(state, null);
    }
    dispatch(receiveScenario(null));
  };
};

export const error = (errors, errorObj) => {
  return {
    type: types.ERROR,
    errors,
    errorObj,
  };
};

export const alert = (alerts, alertObj) => {
  return {
    type: types.ALERT,
    alerts,
    alertObj,
  };
};
