import { combineReducers } from 'redux';
import * as types from './action_types.js';

function datasource(state = null, action = {}) {
  switch (action.type) {
    case types.RECEIVE_DATASOURCE:
      return action.datasource;
    case types.ERROR:
      if (state && $.isPlainObject(state)) {
        state.errors = action.errorObj;
      }
      return state;
    default:
      return state;
  }
}

function errors(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_DATASOURCE:
      return [];
    case types.ERROR:
      return action.errors;
    default:
      return state;
  }
}

export default combineReducers({
  datasource,
  errors,
});
