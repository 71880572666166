const getFormatByKintoneField = (opt) => {
  const format = {
    digit: opt.digit ? opt.digit : null,
    displayScale: opt.displayScale ? opt.displayScale : null,
    unit: opt.unit ? opt.unit : null,
    unitPosition: opt.unitPosition ? opt.unitPosition : null,
  };
  return format;
};

export { getFormatByKintoneField };
