import reducer from 'components/datasources/reducers';
import App from 'components/datasources/container';
import renderer from 'controllers/react_renderer';

window.app.pages.DatasourcesNew = function () {
  renderer.render(reducer, App, 'react-container', {
    datasource: {
      provider: 'kintone',
      kintone: {
        subdomain: '',
        username: '',
        password: '',
        basic_username: '',
        basic_password: '',
        use_password_authentication: false,
      },
    },
  });
};
