import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base/icon';

class Errors extends React.Component {
  static propTypes = {
    errors: PropTypes.array,
    onError: PropTypes.func,
  };

  render() {
    var alert = function (errors) {
      if (!errors || errors.length === 0) return;

      return (
        <div className="alert alert-danger alert-dismissible">
          <button
            type="button"
            onClick={this.close}
            className="close"
            aria-label="Close"
          >
            <Icon name="close" />
          </button>
          <ul>
            {errors.map(function (error, idx) {
              return <li key={idx}>{error}</li>;
            }, this)}
          </ul>
        </div>
      );
    }.call(this, this.props.errors);
    return <div id="alert-container">{alert}</div>;
  }

  close = (e) => {
    if (this.props.onError) {
      this.props.onError([]);
    }
  };
}

export default Errors;
