import React, { useMemo } from 'react';

export default function NumberInput({
  value,
  onChange,
  className,
  disabled,
  readOnly,
  required,
}) {
  return (
    <input
      type="text"
      inputMode="numeric"
      className={className}
      disabled={disabled}
      readOnly={readOnly}
      value={value}
      onChange={(e) => {
        if (readOnly) return;
        const re = /^[-+]?[0-9]*\.?[0-9]*$/;
        const v = e.target.value;
        if (v === '' || re.test(v)) {
          onChange(v);
        }
      }}
      required={required}
    />
  );
}
