import Xhr from 'utils/xhr.js';
import Modal from 'utils/modal';
import * as types from './action_types.js';

export function receiveIntegration(integration) {
  return {
    type: types.RECEIVE_INTEGRATION,
    integration: integration,
  };
}

export function receiveIntegrations(integrations) {
  return {
    type: types.RECEIVE_INTEGRATIONS,
    integrations: integrations,
  };
}

export const setPoppedState = (popedState) => {
  return (dispatch, getState) => {
    if (popedState == 'new') {
      dispatch(newIntegration(false));
    } else if (popedState) {
      dispatch(setIntegration(popedState, false));
    } else {
      dispatch(fetchIntegrations(false));
    }
  };
};

const setHistory = (state, integrationId) => {
  if (window.history && window.history.pushState) {
    var url = `/agents/${state.agent.id}/integrations`;
    if (integrationId == 'new') {
      url = url + '/new';
    } else if (integrationId) {
      url = url + `/${integrationId}/edit`;
    }
    window.history.pushState(integrationId, '', url);
  }
};

export function newIntegration(pushState) {
  return (dispatch, getState) => {
    var state = getState();
    if (pushState) {
      setHistory(state, 'new');
    }
    dispatch(fetchNewIntegration('line_works'));
  };
}

export function fetchNewIntegration(provider) {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/integrations/new`,
      { provider: provider },
      (data) => {
        dispatch(receiveIntegration(data.integration));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
}

export function setIntegration(integrationId, pushState) {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/integrations/${integrationId}`,
      {},
      (data) => {
        if (pushState) {
          setHistory(state, integrationId);
        }
        dispatch(receiveIntegration(data.integration));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
}

export function fetchIntegrations(pushState) {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/integrations`,
      {},
      (data) => {
        if (pushState) {
          setHistory(state, null);
        }
        dispatch(receiveIntegrations(data.integrations));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
}

export function saveIntegration(integration) {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = `agents/${state.agent.id}/integrations`;
    if (integration.id) {
      method = 'PATCH';
      url += `/${integration.id}`;
    } else {
      method = 'POST';
    }
    Xhr.execute(
      method,
      url,
      integration,
      (data) => {
        Modal.toast(
          'alert',
          '設定が保存されました。チャットボットへの反映を行ってください。',
        );
        dispatch(fetchIntegrations(true));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr, 'integration'), xhr.responseJSON));
      },
    );
  };
}

export const deleteIntegration = (integration) => {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = `agents/${state.agent.id}/integrations/${integration.id}`;
    method = 'DELETE';
    Xhr.execute(
      method,
      url,
      {},
      (data) => {
        dispatch(fetchIntegrations(false));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr, 'integration'), xhr.responseJSON));
      },
    );
  };
};

export function clearIntegration(pushState) {
  return (dispatch, getState) => {
    var state = getState();
    if (pushState) {
      setHistory(state, null);
    }
    dispatch(receiveIntegration(null));
  };
}

export const error = (errors, errorObj) => {
  return {
    type: types.ERROR,
    errors,
    errorObj,
  };
};
