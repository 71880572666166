import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'utils/modal';
import Icon from 'components/base/icon';

class Deploy extends React.Component {
  constructor(props) {
    super(props);
  }

  onDeployClick = () => {
    Modal.confirm(
      'チャットボットの設定を反映します。よろしいですか？',
      null,
      () => {
        this.props.handler();
      },
    );
  };

  render() {
    return (
      <button
        onClick={this.onDeployClick}
        type="button"
        className="btn btn-primary"
      >
        <Icon name="cached" />
        チャットボットの反映
      </button>
    );
  }
  componentDidMount() {}
}

Deploy.propTypes = {
  done: PropTypes.bool.isRequired,
};

Deploy.defaultProps = {};

export default Deploy;
