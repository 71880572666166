import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/admins';
import Pagination from 'components/base/pagination';
import Icon from 'components/base/icon';

class Index extends React.Component {
  static propTypes = {
    filter: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    navi: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.filters = [
      { value: 'available', name: '有効' },
      { value: 'suspended', name: '停止' },
      { value: 'trial', name: '試用中' },
      { value: 'all', name: 'すべて' },
    ];

    this.refSearchText = React.createRef();
  }

  render() {
    return (
      <div id="users-container" className="container-fluid">
        <h3 className="mt-3">ユーザー一覧</h3>
        <Pagination navi={this.props.navi} onClick={this.onPaginationClick}>
          <div className="float-left">
            <div className="form-inline mb-4">
              <div className="form-group">
                <label>絞り込み： </label>
                <select
                  className="form-control"
                  value={this.props.filter}
                  onChange={this.onFilterChange}
                >
                  {this.filters.map((o) => {
                    return (
                      <option key={o.value} value={o.value}>
                        {o.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group ml-3">
                <input
                  className=" mr-2"
                  type="text"
                  defaultValue=""
                  ref={this.refSearchText}
                />
                <a className="btn btn-primary" href="#!" onClick={this.search}>
                  検索
                </a>
              </div>
            </div>
          </div>
        </Pagination>
        <div>
          <a href="#!" onClick={this.newUser}>
            <Icon name="add_circle_outline" />
            ユーザーを追加する
          </a>
        </div>
        <div className="users-wrapper">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="text-nowrap">ID</th>
                <th className="text-nowrap">会社名</th>
                <th className="text-nowrap">担当者名</th>
                <th className="text-nowrap">E-Mail</th>
                <th className="text-nowrap">作成日</th>
                <th className="text-nowrap">
                  利用プラン
                  <br />
                  追加シナリオ/会話数
                </th>
                <th className="text-nowrap">試用期限</th>
                <th className="text-nowrap">停止</th>
                <th className="text-nowrap">当月会話数</th>
                <th className="text-nowrap" />
              </tr>
            </thead>
            <tbody>
              {this.props.users.map(function (user) {
                return (
                  <IndexRow
                    key={user.id}
                    user={user}
                    actions={this.props.actions}
                  />
                );
              }, this)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  componentDidMount() {}

  newUser = () => {
    this.props.actions.newUser();
  };

  onFilterChange = (e) => {
    this.props.actions.setFilter(e.target.value);
  };

  search = (e) => {
    e.preventDefault();
    var val = $(this.refSearchText.current).val();
    this.props.actions.setSearchText(val);
  };

  onPaginationClick = (page) => {
    this.props.actions.setPage(page);
  };
}

class IndexRow extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    actions: PropTypes.object.isRequired,
  };

  edit = () => {
    this.props.actions.receiveUser(this.props.user);
  };

  getServicePlanName = (servicePlan) => {
    switch (servicePlan) {
      case 'trial':
        return '試用';
      case 'starter':
        return 'スターター';
      case 'light':
        return 'ライト';
      case 'standard':
        return 'スタンダード';
      case 'premium':
        return 'プレミアム';
      case 'enterprise':
        return 'エンタープライズ';
    }
  };

  render() {
    var user = this.props.user;
    var dateFormat = 'YYYY/MM/DD';
    return (
      <tr>
        <td>{user.id}</td>
        <td>{user.company}</td>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{moment(user.created_at).format(dateFormat)}</td>
        <td>
          {this.getServicePlanName(user.service_plan)}
          <br />
          {user.additional_scenarios}/{user.additional_conversations}
        </td>
        <td>
          {user.trial_expires_at
            ? moment(user.trial_expires_at).format(dateFormat)
            : ''}
        </td>
        <td>{user.suspended ? '✔' : ''}</td>
        <td className="text-right">{user.conversation_count || 0}</td>
        <td>
          <a href="#!" onClick={this.edit} className="btn btn-primary">
            編集
          </a>
        </td>
      </tr>
    );
  }
}

export default Index;
