import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/scenarios';
import Icon from 'components/base/icon';
import Modal from 'utils/modal';

class ListItem extends React.Component {
  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick(this.props.scenario.id);
  };

  getScenarioType(t) {
    switch (t) {
      case 'general_search':
        return '汎用検索・更新';
      case 'general_register':
        return '汎用登録';
    }
  }

  getIcon(t) {
    switch (t) {
      case 'general_search':
        return 'search';
      case 'general_register':
        return 'create';
    }
  }

  getPhrase(t, name) {
    if (name) {
      name = name.split('\n')[0];
    }
    switch (t) {
      case 'general_search':
        return `◯◯の${name}を教えて`;
      case 'general_register':
        return `${name}を登録`;
    }
    return '';
  }

  onDeleteClick = (e) => {
    e.preventDefault();
    Modal.confirm(
      'シナリオを削除します。この操作は元に戻せません。よろしいですか？',
      null,
      () => {
        this.props.onDelete(this.props.scenario);
      },
    );
  };

  onDupClick = (e) => {
    e.preventDefault();
    this.props.onDup(this.props.scenario.id);
  };

  render() {
    var scenario = this.props.scenario;
    var className = styles[`scenario_${scenario.scenario_type}`];
    var icon = this.getIcon(scenario.scenario_type);
    var ds = scenario.datasource;
    var scenarioType = this.getScenarioType(scenario.scenario_type);
    if (ds) {
      scenarioType = ds.name + ' > ' + scenarioType;
    }
    var phrase = this.getPhrase(scenario.scenario_type, scenario.wakeup_word);

    return (
      <div className="col-md-6">
        <div className={`${className} card mb-4`}>
          <div className="card-body">
            <div className={styles.cardTitle}>
              <a href="#!" onClick={this.handleClick}>
                <Icon name={icon} />
                {scenario.name}
              </a>
            </div>

            <div className={`${styles.cardSubTitle} text-muted`}>
              {scenarioType}
            </div>
            <div className={`${styles.chat}`}>
              <span>{phrase}</span>
            </div>
            <div className="text-right">
              <a className="ml-4" href="#!" onClick={this.handleClick}>
                <Icon name="edit" />
                編集
              </a>
              <a className="ml-4" href="#!" onClick={this.onDupClick}>
                <Icon name="content_copy" />
                複製
              </a>
              <a className="ml-2" href="#!" onClick={this.onDeleteClick}>
                <Icon name="delete" />
                削除
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  newScenario = (e) => {
    e.preventDefault();
    this.props.actions.newScenario(true);
  };

  setScenario = (id) => {
    this.props.actions.setScenario(id, true);
  };

  duplicateScenario = (id) => {
    this.props.actions.duplicateScenario(id);
  };

  deleteScenario = (id) => {
    this.props.actions.deleteScenario(id);
  };

  render() {
    return (
      <div className={styles.mainBody}>
        <h3>シナリオ一覧</h3>
        <div>
          <a href="#!" onClick={this.newScenario}>
            <Icon name="add_circle_outline" />
            シナリオを作成する
          </a>
        </div>
        <div className="row mt-4 mb-4">
          {this.props.scenarios.map((scenario) => {
            return (
              <ListItem
                key={scenario.id}
                scenario={scenario}
                onClick={this.setScenario}
                onDup={this.duplicateScenario}
                onDelete={this.deleteScenario}
              />
            );
          })}
        </div>
      </div>
    );
  }
  componentDidMount() {}
}

Index.propTypes = {
  scenarios: PropTypes.array.isRequired,
};

Index.defaultProps = {};

export default Index;
