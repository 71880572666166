import React from 'react';
import styles from 'src/scenarios';
import { DragSource, DropTarget } from 'react-dnd';

class SelectListItem extends React.Component {
  onFieldCodeChange = (e) => {
    e.preventDefault();
    var key = e.target.value;
    var r = { ...this.props.data };
    this.props.field_options.forEach((opt) => {
      if (opt.code == key) {
        let format = null;
        if (opt.type === 'NUMBER') {
          format = JSON.stringify(this.props.getFormatByKintoneField(opt));
        }
        r.field_type = opt.type;
        r.field_code = opt.code;
        r.field_label = opt.label;
        r.format = format;
        this.props.onChange(this.props.index, 'select_fields', r);
      }
    });
  };

  onAdd = (e) => {
    e.preventDefault();
    this.props.onAdd(this.props.index, 'select_fields');
  };

  onRemove = (e) => {
    e.preventDefault();
    this.props.onRemove(this.props.index, 'select_fields');
  };

  render() {
    if (
      !this.props.connectDragPreview ||
      !this.props.connectDragSource ||
      !this.props.connectDropTarget
    )
      return;

    return this.props.connectDragPreview(
      this.props.connectDropTarget(
        <div className="row">
          <div className="col-sm-1">
            {this.props.connectDragSource(
              <button type="button" className="btn-sm handle text-center">
                <i className="material-icons">reorder</i>
              </button>,
              { dropEffect: 'move' },
            )}
          </div>
          <div className="col-sm-5">
            <select
              className="form-control"
              id={this.props.id}
              value={this.props.data.field_code || ''}
              onChange={this.onFieldCodeChange}
            >
              <option key="#blank" value="" />
              {this.props.field_options.map((o) => {
                return (
                  <option key={o.code} value={o.code}>
                    {o.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-6 align-self-center">
            <span className={styles.buttons}>
              <a href="#!" onClick={this.onAdd}>
                <i className="material-icons mr-1">add_circle</i>
              </a>
              {this.props.removable && (
                <a href="#!" onClick={this.onRemove}>
                  <i className="material-icons mr-1">remove_circle</i>
                </a>
              )}
            </span>
          </div>
        </div>,
      ),
    );
  }
}

const itemSource = {
  beginDrag(props) {
    return props;
  },
};

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const itemTarget = {
  hover(hoverProps, monitor, hoverComponent) {
    let dragProps = monitor.getItem();

    if (!hoverProps.data || !dragProps.data) return;
    if (hoverProps.data.id == dragProps.data.id) return;

    dragProps.onChangeOrder(dragProps.data, hoverProps.data, 'select_fields');
  },
};

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

var DecorateListItem = DragSource(
  'select_list_item',
  itemSource,
  collectSource,
)(SelectListItem);
DecorateListItem = DropTarget(
  'select_list_item',
  itemTarget,
  collectTarget,
)(DecorateListItem);

export default DecorateListItem;
