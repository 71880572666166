const sortKintoneFields = function (properties, layout) {
  const fields = [];
  const builtins = [];
  properties = { ...properties };
  if (layout) {
    layout.layout.forEach((section) => {
      if (section.type === 'GROUP') {
        section.layout.forEach((subSection) => {
          subSection.fields.forEach((field) => {
            if (properties[field.code]) {
              fields.push(properties[field.code]);
              delete properties[field.code];
            }
          });
        });
      } else if (section.type === 'SUBTABLE') {
        if (properties[section.code]) {
          fields.push(properties[section.code]);
          delete properties[section.code];
        }
      } else if (section.type === 'ROW') {
        section.fields.forEach((field) => {
          if (properties[field.code]) {
            fields.push(properties[field.code]);
            delete properties[field.code];
          }
        });
      }
    });
  }
  for (const code in properties) {
    builtins.push(properties[code]);
  }

  return [...builtins, ...fields];
};

export default sortKintoneFields;
