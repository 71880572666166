import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/admins';
import DatePicker from 'components/base/datepicker';

class Edit extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    var user = props.user;

    this.servicePlans = [
      { value: 'trial', name: '試用' },
      { value: 'starter', name: 'スターター（1シナリオ）' },
      { value: 'light', name: 'ライト（5シナリオ）' },
      { value: 'standard', name: 'スタンダード（10シナリオ）' },
      { value: 'premium', name: 'プレミアム（50シナリオ）' },
      { value: 'enterprise', name: 'エンタープライズ（シナリオ無制限）' },
    ];

    this.state = {
      email: user.email,
      name: user.name,
      company: user.company,
      service_plan: user.service_plan,
      trial_expires_at: user.trial_expires_at,
      suspended: user.suspended,
      additional_scenarios: user.additional_scenarios || 0,
      additional_conversations: user.additional_conversations || 0,
    };
  }

  save = (e) => {
    e.preventDefault();
    var state = this.state;
    var user = {
      id: this.props.user.id,
      email: state.email,
      name: state.name,
      company: state.company,
      service_plan: state.service_plan,
      trial_expires_at: state.trial_expires_at,
      suspended: state.suspended,
      additional_scenarios: state.additional_scenarios || '0',
      additional_conversations: state.additional_conversations || '0',
    };
    this.props.actions.saveUser(user);
  };

  cancel = (e) => {
    e.preventDefault();
    this.props.actions.receiveUser(null);
  };

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  onCompanyChange = (e) => {
    this.setState({ company: e.target.value });
  };

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  onServicePlanChange = (e) => {
    this.setState({
      service_plan: e.target.value,
      additional_scenarios: '0',
      additional_conversations: '0',
    });
  };

  getAdditionalScenarioOptions = () => {
    var options = [];
    for (var i = 0; i <= 200; i += 5) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return options;
  };

  getAdditionalConversationOptions = () => {
    var options = [];
    for (var i = 0; i <= 200000; i += 5000) {
      options.push(
        <option key={i} value={i}>
          {i.toLocaleString()}
        </option>,
      );
    }
    return options;
  };

  onTrialExpiresAtChange = (val) => {
    this.setState({ trial_expires_at: val });
  };

  onSuspendedChange = (e) => {
    this.setState({ suspended: $(e.target).prop('checked') });
  };

  onAdditionalScenariosChange = (e) => {
    this.setState({ additional_scenarios: e.target.value });
  };

  onAdditionalConversationsChange = (e) => {
    this.setState({ additional_conversations: e.target.value });
  };

  render() {
    var isInvalid = (name) => {
      if (this.props.user.errors && this.props.user.errors[name]) {
        return 'is-invalid';
      }
      return '';
    };
    return (
      <div className={styles.userContainer}>
        <h3>ユーザーの設定</h3>
        <form className="">
          {!!this.props.user.id && (
            <div className="form-group">
              <label htmlFor="name">ID</label>
              <div>{this.props.user.id}</div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="name" className="required">
              E-Mail
            </label>
            <input
              type="text"
              className={`form-control ${isInvalid('email')}`}
              id="name"
              value={this.state.email || ''}
              onChange={this.onEmailChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="company" className="required">
              会社名
            </label>
            <input
              type="text"
              className={`form-control ${isInvalid('company')}`}
              id="name"
              value={this.state.company || ''}
              onChange={this.onCompanyChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name" className="required">
              担当者名
            </label>
            <input
              type="text"
              className={`form-control ${isInvalid('name')}`}
              id="name"
              value={this.state.name || ''}
              onChange={this.onNameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="service_plan" className="required">
              利用プラン
            </label>
            <select
              className="form-control"
              value={this.state.service_plan}
              onChange={this.onServicePlanChange}
            >
              {this.servicePlans.map((o) => {
                return (
                  <option key={o.value} value={o.value}>
                    {o.name}
                  </option>
                );
              })}
            </select>
          </div>
          {['trial', 'enterprise'].indexOf(this.state.service_plan) < 0 && (
            <div className="form-group">
              <label htmlFor="additional_scenarios" className="required">
                追加シナリオ数
              </label>
              <select
                className="form-control"
                value={this.state.additional_scenarios}
                onChange={this.onAdditionalScenariosChange}
              >
                <option value=""></option>
                {this.getAdditionalScenarioOptions()}
              </select>
            </div>
          )}
          {['trial', 'enterprise'].indexOf(this.state.service_plan) < 0 && (
            <div className="form-group">
              <label htmlFor="additional_conversations" className="required">
                追加会話数
              </label>
              <select
                className="form-control"
                value={this.state.additional_conversations}
                onChange={this.onAdditionalConversationsChange}
              >
                <option value=""></option>
                {this.getAdditionalConversationOptions()}
              </select>
            </div>
          )}
          {this.state.service_plan == 'trial' && !!this.props.user.id && (
            <div className="form-group">
              <label htmlFor="name">試用期限</label>
              <DatePicker
                id="user-trial-expires-at"
                value={
                  this.state.trial_expires_at &&
                  moment(this.state.trial_expires_at).format('YYYY-MM-DD')
                }
                className={isInvalid('trial')}
                onChange={this.onTrialExpiresAtChange}
              />
            </div>
          )}
          {!!this.props.user.id && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="user-suspended"
                value="1"
                checked={!!this.state.suspended}
                onChange={this.onSuspendedChange}
              />
              <label
                className={`form-check-label ${isInvalid('suspended')}`}
                htmlFor="user-suspended"
              >
                停止
              </label>
            </div>
          )}
          <button type="button" className="btn btn-primary" onClick={this.save}>
            保存する
          </button>
          <button className="ml-2 btn" type="button" onClick={this.cancel}>
            キャンセル
          </button>
        </form>
      </div>
    );
  }
  componentDidMount() {}
}

export default Edit;
