import { combineReducers } from 'redux';
import * as types from './action_types.js';

function agents(state = [], action = {}) {
  return state;
}

function agent(state = {}, action = {}) {
  switch (action.type) {
    case types.RECEIVE_AGENT:
      return action.agent;
    case types.ERROR:
      if (state && $.isPlainObject(state)) {
        state.errors = action.errorObj;
      }
      return state;
    default:
      return state;
  }
}

function kintoneSettings(state = [], action = {}) {
  return state;
}

function errors(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_AGENTS:
    case types.RECEIVE_AGENT:
    case types.ERROR:
      return action.errors;
    default:
      return state;
  }
}

export default combineReducers({
  agents,
  agent,
  kintoneSettings,
  errors,
});
