import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Sidebar from 'components/shared/agents/sidebar';
import styles from 'src/integrations';
import Errors from 'components/base/errors';
import * as actions from './actions';
import Index from './index';
import Edit from './edit';

class Container extends React.Component {
  render() {
    return (
      <div className={`d-flex ${styles.container}`}>
        <Sidebar
          agents={this.props.agents}
          agent={this.props.agent}
          active="integrations"
        />
        <div className={styles.main}>
          <Errors
            errors={this.props.errors}
            onError={this.props.actions.error}
          />
          {this.props.integration ? (
            <Edit
              agent={this.props.agent}
              integration={this.props.integration}
              actions={this.props.actions}
            />
          ) : (
            <Index
              integrations={this.props.integrations}
              actions={this.props.actions}
            />
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (window.history && window.history.pushState) {
      $(window).on(
        'popstate',
        function (e) {
          var state = e.originalEvent.state;
          this.props.actions.setPoppedState(state);
        }.bind(this),
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    agents: state.agents,
    agent: state.agent,
    integrations: state.integrations,
    integration: state.integration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Container);
