import { combineReducers } from 'redux';
import * as types from './action_types';

function users(state = [], action) {
  switch (action.type) {
    case types.RECEIVE_USERS:
      return [].concat(action.users);
    default:
      return state;
  }
}

function user(state = null, action) {
  switch (action.type) {
    case types.RECEIVE_USER:
      if (action.user) {
        return $.extend({}, action.user);
      }
      return null;
    case types.RECEIVE_USERS:
      return null;
    case types.ERROR:
      if (state && $.isPlainObject(state)) {
        state.errors = action.errorObj;
      }
      return state;
    default:
      return state;
  }
}

function errors(state = [], action) {
  switch (action.type) {
    case types.RECEIVE_USERS:
    case types.RECEIVE_USER:
      return [];
    case types.ERROR:
      return action.errors;
    default:
      return state;
  }
}

function filter(state = 'available', action) {
  switch (action.type) {
    case types.SET_FILTER:
      return action.filter;
    default:
      return state;
  }
}

function searchText(state = '', action) {
  switch (action.type) {
    case types.SET_SEARCH_TEXT:
      return action.searchText;
    default:
      return state;
  }
}

function navi(state = { limit: 50, page: 1, count: 0 }, action) {
  switch (action.type) {
    case types.RECEIVE_USERS:
      return $.extend({}, action.navi);
    default:
      return state;
  }
}

export default combineReducers({
  users,
  user,
  filter,
  searchText,
  navi,
  errors,
});
