import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/agents';

class Edit extends React.Component {
  static propTypes = {
    agent: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    var agent = this.props.agent;
    this.state = {
      name: agent.name || '',
    };
  }

  save = (e) => {
    e.preventDefault();
    var agent = {
      id: this.props.agent.id,
      name: this.state.name,
    };
    this.props.actions.saveAgent(agent);
  };

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  render() {
    var isInvalid = (name) => {
      if (this.props.agent.errors && this.props.agent.errors[name]) {
        return 'is-invalid';
      }
      return '';
    };
    return (
      <div className="mt-5">
        <div className={styles.editContainer}>
          <h3>チャットボットの設定</h3>
          <form>
            <div className="form-group">
              <label htmlFor="name" className="required">
                名前
              </label>
              <input
                type="text"
                className={`form-control ${isInvalid('name')}`}
                id="name"
                value={this.state.name || ''}
                onChange={this.onNameChange}
              />
            </div>
            <div className="mt-3 mb-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.save}
              >
                保存する
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  componentDidMount() {}
}

export default Edit;
