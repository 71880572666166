import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import UaUtil from 'utils/ua';
import Sidebar from 'components/shared/agents/sidebar';
import styles from 'src/scenarios';
import Errors from 'components/base/errors';
import Alerts from 'components/base/alerts';
import * as actions from './actions';
import Index from './index';
import Edit from './edit';

class Container extends React.Component {
  render() {
    return (
      <div className={`d-flex ${styles.container}`}>
        <Sidebar
          agents={this.props.agents}
          agent={this.props.agent}
          active="scenarios"
        />
        <div className={styles.main}>
          <Errors
            errors={this.props.errors}
            onError={this.props.actions.error}
          />
          <Alerts
            alerts={this.props.alerts}
            onAlert={this.props.actions.alert}
          />
          {this.props.scenario ? (
            <DndProvider
              backend={UaUtil.isMobileOrTablet() ? TouchBackend : HTML5Backend}
            >
              <Edit
                agent={this.props.agent}
                scenario={this.props.scenario}
                datasources={this.props.datasources}
                actions={this.props.actions}
              />
            </DndProvider>
          ) : (
            <Index
              scenarios={this.props.scenarios}
              actions={this.props.actions}
            />
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (window.history && window.history.pushState) {
      $(window).on(
        'popstate',
        function (e) {
          var state = e.originalEvent.state;
          this.props.actions.setPoppedState(state);
        }.bind(this),
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    agents: state.agents,
    datasources: state.datasources,
    agent: state.agent,
    scenarios: state.scenarios,
    scenario: state.scenario,
    alerts: state.alerts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Container);
