import reducer from 'components/agents/reducers';
import App from 'components/agents/container';
import renderer from 'controllers/react_renderer';

window.app.pages.AgentsNew = function () {
  renderer.render(reducer, App, 'react-container', {
    agents: app.data.settings.agents,
    agent: {
      id: 0,
      name: '',
    },
  });
};
