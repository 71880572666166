import Xhr from 'utils/xhr.js';
import * as types from './action_types.js';

export function receiveAgent(agent) {
  return {
    type: types.RECEIVE_AGENT,
    agent: agent,
  };
}

export function newAgent() {
  return receiveAgent({
    id: 0,
    name: '',
  });
}

export function saveAgent(agent) {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = 'agents';
    if (agent.id) {
      method = 'PATCH';
      url += `/${agent.id}`;
    } else {
      method = 'POST';
    }
    Xhr.execute(
      method,
      url,
      agent,
      (data) => {
        window.location.href = `/agents/${data.agent.id}/scenarios`;
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr, 'agent'), xhr.responseJSON));
      },
    );
  };
}

export const error = (errors, errorObj) => {
  return {
    type: types.ERROR,
    errors,
    errorObj,
  };
};
