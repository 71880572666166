import { I18n } from 'i18n-js';

var app = {};
app.data = {};
app.pages = {};
app.constants = {};
app.concerns = {};
app.assetPath = {};
app.assetPath.images = {};

app.concerns.init = function (className) {
  //ReactPerf.start();
  /* You should call below from the browser's console before you trigger the event you're trying to monitor
     ReactPerf.stop();
     ReactPerf.printInclusive();
  */

  const i18n = new I18n(i18nTranslations);
  i18n.defaultLocale = app.language;
  i18n.locale = app.language;
  window.I18n = i18n;
  if (app.pages[className]) {
    app.pages[className]();
  }
};
window.app = app;
