import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base/icon';

class Pagination extends React.Component {
  static propTypes = {
    navi: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    pageNo: PropTypes.bool,
  };

  static defaultProps = {
    pageNo: true,
  };

  render() {
    var pageMax = Math.ceil(this.props.navi.count / this.props.navi.limit);
    var currentPage = this.props.navi.page;
    if (this.props.navi.count === 0) {
      return (
        <div className="pagination-wrapper clearfix">{this.props.children}</div>
      );
    }
    var pages = [];
    var start = currentPage - 2;
    if (start < 1) start = 1;
    for (var i = start; i < start + 5; i++) {
      if (i > pageMax) break;
      pages.push(i);
    }
    var rowBase = (currentPage - 1) * this.props.navi.limit;
    return (
      <div className="pagination-wrapper clearfix">
        <nav className="">
          <div>{this.props.children}</div>
          <div className="float-right">
            <ul className="pagination">
              {this.props.pageNo && (
                <li className="pagination-page-no">
                  ({rowBase + 1}-
                  {currentPage == pageMax
                    ? this.props.navi.count
                    : rowBase + this.props.navi.limit}
                  /{this.props.navi.count})
                </li>
              )}
              <li
                key="previous"
                className={'page-item ' + (currentPage == 1 ? 'disabled' : '')}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={this.onLinkClick.bind(this, 1)}
                  aria-label="First"
                >
                  &laquo;
                </a>
              </li>
              {pages.map(function (page) {
                return (
                  <li
                    key={'page#' + page}
                    className={
                      'page-item ' + (currentPage == page ? 'active' : '')
                    }
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={this.onLinkClick.bind(this, page)}
                    >
                      {page}
                    </a>
                  </li>
                );
              }, this)}
              <li
                key="next"
                className={
                  'page-item ' + (currentPage == pageMax ? 'disabled' : '')
                }
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={this.onLinkClick.bind(this, currentPage + 1)}
                  aria-label="Next"
                >
                  &rsaquo;
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }

  componentDidMount() {}

  onLinkClick = (page, e) => {
    e.preventDefault();
    if (page == this.props.navi.page) return;
    var pageMax = Math.ceil(this.props.navi.count / this.props.navi.limit);
    if (page > pageMax) return;
    this.props.onClick(page);
  };
}

export default Pagination;
