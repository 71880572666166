import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import Deploy from './deploy';

class Container extends React.Component {
  render() {
    return (
      <Deploy done={this.props.done} handler={this.props.actions.deploy} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    agent: state.agent,
    done: state.done,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Container);
