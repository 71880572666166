const getLinkValidateErrorMessage = (value, linkType) => {
  let res = null;
  switch (linkType) {
    case 'WEB':
      if (!value.match(/https?:\/\/[\w\-_.!?~*'():@&=+$,;?/#%]+/)) {
        res = '入力がURLではありません';
      }
      break;
    case 'MAIL':
      if (!value.match(/[\w.!#$'%&*+/=?^_\-`{}|~]+@[\w\-.]/)) {
        res = '入力がメールアドレスではありません';
      }
      break;
    // CALL はkintoneでバリデーションを行っていない
  }
  return res;
};

export { getLinkValidateErrorMessage };
