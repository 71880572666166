import Xhr from 'utils/xhr.js';
import * as types from './action_types.js';

export function receiveDatasource(datasource) {
  return {
    type: types.RECEIVE_DATASOURCE,
    datasource,
  };
}

export function saveDatasource(datasource) {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = `datasources`;
    if (datasource.id) {
      method = 'PATCH';
      url += `/${datasource.id}`;
    } else {
      method = 'POST';
    }
    Xhr.execute(
      method,
      url,
      datasource,
      (data) => {
        if (datasource.id) {
          dispatch(receiveDatasource(data.datasource));
        } else {
          window.location.href = '/agents';
        }
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr, 'datasource'), xhr.responseJSON));
      },
    );
  };
}

export const error = (errors, errorObj) => {
  return {
    type: types.ERROR,
    errors,
    errorObj,
  };
};
