import React from 'react';
import PropTypes from 'prop-types';
import { wrapper } from 'components/base/dialog';
import Errors from 'components/base/errors';
import Textarea from 'components/base/textarea';

class QueryFieldDialog extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    lookupFieldMappings: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    var data = this.props.data;
    var state = {
      errors: [],
      allow_to_skip: data.allow_to_skip,
      additional_explanation: data.additional_explanation
        ? data.additional_explanation
        : '',
      lookup_drill_down_app: data.lookup_drill_down_app,
      lookup_drill_down_field: data.lookup_drill_down_field,
      use_default: data.use_default,
      check_use_default: data.use_default && data.use_default !== 'unused',
    };
    this.state = state;
  }

  onChangeDefaultValueCheckBox = (e) => {
    let checkUseDefault = $(e.target).prop('checked');
    let useDefault;
    if (checkUseDefault) {
      useDefault = 'user_name';
    } else {
      useDefault = 'unused';
    }

    this.setState({
      check_use_default: checkUseDefault,
      use_default: useDefault,
      lookup_drill_down_app: null,
      lookup_drill_down_field: null,
    });
  };

  onChangeValueInput = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  onAllowToSkipChange = (e) => {
    var state = { allow_to_skip: !$(e.target).prop('checked') };
    this.setState(state);
  };

  onChangeAdditionalExplanation = (e) => {
    this.setState({
      additional_explanation: e.target.value,
    });
  };

  onLookupDrillDownChange = (e) => {
    let app = null;
    let field = null;
    if ($(e.target).prop('checked')) {
      app = this.props.lookupFieldMappings[this.props.data.field_code].app;
      field = this.props.lookupFieldMappings[this.props.data.field_code].field;
    }
    const state = {
      lookup_drill_down_app: app,
      lookup_drill_down_field: field,
      check_use_default: false,
      use_default: 'unused',
    };
    this.setState(state);
  };

  getDefaultValueOptions = (fieldType) => {
    return (
      <div className="form-row">
        <div className="form-group col-md-6">
          <select
            className="form-control"
            id="default_value_type"
            onChange={(e) => this.onChangeValueInput(e, 'use_default')}
            value={this.state.use_default || ''}
          >
            <option value="user_name">氏名を自動入力</option>
            <option value="user_mail">メールアドレスを自動入力</option>
            <option value="login_id">ログインIDを自動入力</option>
            <option value="external_key">連携キーを自動入力</option>
          </select>
        </div>
      </div>
    );
  };

  render = () => {
    var err;
    if (this.state.errors) {
      err = (
        <Errors
          errors={this.state.errors}
          onError={(errors) => {
            this.setState({ errors });
          }}
        />
      );
    }

    return (
      <div className="modal-content">
        {this.props.modalHeader({
          title: '検索項目の設定',
        })}
        <div className="modal-body">
          {err}
          <form>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label>フィールド名</label>
                <div className="help-block">{this.props.data.field_label}</div>
              </div>
              <div className="col-sm-6 form-group">
                <label>フィールドタイプ</label>
                <div className="help-block">{this.props.data.field_type}</div>
              </div>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="allow-to-skip"
                value="1"
                checked={!this.state.allow_to_skip}
                onChange={this.onAllowToSkipChange}
              />
              <label className="form-check-label" htmlFor="allow-to-skip">
                必須入力
              </label>
            </div>
            {this.props.lookupFieldMappings[this.props.data.field_code] !==
            undefined ? (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="lookup-drill-down"
                  value="1"
                  checked={this.state.lookup_drill_down_app !== null}
                  onChange={this.onLookupDrillDownChange}
                />
                <label className="form-check-label" htmlFor="lookup-drill-down">
                  ルックアップ元アプリから選択する
                </label>
              </div>
            ) : null}
            {this.props.data.field_type === 'SINGLE_LINE_TEXT' ? (
              <>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="use-default"
                    value="1"
                    checked={this.state.check_use_default}
                    onChange={(e) => this.onChangeDefaultValueCheckBox(e)}
                  />
                  <label className="form-check-label" htmlFor="use-default">
                    固定の値
                  </label>
                </div>
                {this.state.check_use_default
                  ? this.getDefaultValueOptions(this.props.data.field_type)
                  : null}
              </>
            ) : null}
            <label htmlFor="additional-explanation-input">補足説明</label>
            <Textarea
              maxRows={5}
              minRows={1}
              className={'form-control'}
              id="additional-explanation-input"
              value={this.state.additional_explanation}
              onChange={this.onChangeAdditionalExplanation}
              maxLength={255}
            />
          </form>
        </div>
        {this.props.modalFooter({
          onOk: this.onOk,
        })}
      </div>
    );
  };

  onOk = () => {
    const additional_explanation = this.state.additional_explanation
      ? this.state.additional_explanation
      : null;
    this.props.onChange({
      allow_to_skip: this.state.allow_to_skip,
      additional_explanation: additional_explanation,
      lookup_drill_down_app: this.state.lookup_drill_down_app,
      lookup_drill_down_field: this.state.lookup_drill_down_field,
      use_default: this.state.use_default,
    });
    this.props.close();
  };

  componentDidMount() {}
}

export default wrapper(QueryFieldDialog);
