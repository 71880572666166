import reducer from 'components/scenarios/reducers';
import App from 'components/scenarios/container';
import renderer from 'controllers/react_renderer';
import { newScenario } from 'components/scenarios/actions';

window.app.pages.ScenariosNew = function () {
  var store = renderer.render(reducer, App, 'react-container', {
    agents: app.data.settings.agents,
    agent: app.data.settings.agent,
    datasources: app.data.datasources,
    scenarios: app.data.scenarios,
  });
  store.dispatch(newScenario(false));
};
