import UrlUtil from 'utils/url.js';
import Spinner from 'utils/spinner.js';

// utility class for ajax

const Xhr = {
  getErrors: function (xhr, model) {
    if (xhr.status == 500) {
      return ['内部エラーが発生しました。しばらくして再実行してください。'];
    } else if (xhr.status == 400) {
      return ['不正なリクエストです。'];
    } else if (xhr.status == 403) {
      return ['権限がありません。'];
    } else if (xhr.status == 404) {
      return ['指定されたものが見つかりません。'];
    } else if (xhr.responseJSON && model) {
      var key = model.replace('/', '_');
      var data = xhr.responseJSON;
      if ($.isPlainObject(data) && data[key]) {
        var errors = data[key].errors;
        if (errors) {
          return Xhr.getFullMessages(errors, model);
        }
      }
      if (data.errors) data = data.errors;
      return Xhr.getFullMessages(data, model);
    } else if (xhr.status == 422) {
      return ['リクエストを実行できませんでした。'];
    } else {
      return [`エラーが発生しました。ステータス: ${xhr.status}`];
    }
  },
  getFullMessages: function (json, model) {
    if ($.isArray(json)) {
      return json;
    }
    var msgs = [];
    if (!$.isPlainObject(json)) return msgs;
    $.each(json, function (key, val) {
      if (key == 'error') {
        // see /error.json.jbuilder
        msgs.push(val.toString());
      } else if ($.isArray(val)) {
        var attrName =
          key == 'base'
            ? ''
            : I18n.t('activerecord.attributes.' + model + '.' + key);
        if ($.isPlainObject(attrName)) {
          attrName = attrName.one;
        }
        $.each(val, function (i, v) {
          var msg = I18n.t('errors.format', {
            attribute: attrName,
            message: v,
          });
          msgs.push(msg);
        });
      }
    });
    return msgs;
  },
  _execute: (function () {
    var _spinner = null;

    return function (opts, callback, errback, noSpinner) {
      if (!noSpinner) {
        if (_spinner) return;
        _spinner = new Spinner();
      }

      var hasError = false;

      $.ajax(opts)
        .done(function (data, status, xhr) {
          if (!noSpinner && _spinner) {
            _spinner.stop();
            _spinner = null;
          }

          if (callback) {
            callback(data);
          }
        })
        .fail(function (xhr, status, error) {
          hasError = true;

          if (!noSpinner && _spinner) {
            _spinner.stop();
            _spinner = null;
          }

          if (errback) {
            errback(xhr, status, error);
          }
        })
        .always(function (xhr, status) {
          if (hasError) {
            var $elm = $('#alert-container');
            if ($elm.length > 0) {
              $(window).scrollTop($elm.offset().top - 80); // -80 is for fixed headers
            } else {
              // $(window).scrollTop(0);
            }
          }
        });
    };
  })(),
  getOptions: function (method, url, data) {
    var opts = {
      method: method,
      url: UrlUtil.api(url),
      dataType: 'json',
    };

    if ($.inArray(method.toUpperCase(), ['GET', 'DELETE']) >= 0) {
      opts.data = data;
    } else {
      opts.contentType = 'application/json';
      opts.data = JSON.stringify(data);
    }
    return opts;
  },
  execute: function (method, url, data, callback, errback, noSpinner) {
    var opts = Xhr.getOptions(method, url, data);
    return Xhr._execute(opts, callback, errback, noSpinner);
  },
};

export default Xhr;
