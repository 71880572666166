if (typeof $.fn !== 'undefined') {
  $.fn.spinner = function () {
    var caller = this;
    var component = new SpinnerUtil(this);
    return component;
  };
}

var SpinnerUtil = function ($element) {
  var opts = {};
  if (!$element) {
    $element = $('<div id="spinner-backdrop" class="spinner-backdrop"></div>');
    $('body').append($element);
  }
  if (typeof Spinner == 'undefined') return;
  this._spinner = new Spinner(opts).spin($element.get(0));
};

SpinnerUtil.prototype = {
  stop: function () {
    if (this._spinner) this._spinner.stop();
    $('#spinner-backdrop').remove();
  },
};

export default SpinnerUtil;
