import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/datasources';

class Edit extends React.Component {
  static propTypes = {
    datasource: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    var ds = this.props.datasource;

    this.state = {
      provider: ds.provider,
      name: ds.name,
    };
    if (ds.provider == 'kintone') {
      ds = ds.kintone;
      this.state = $.extend(this.state, {
        child_id: ds.id,
        subdomain: ds.subdomain,
        use_password_authentication: ds.use_password_authentication,
        username: ds.username,
        password: ds.password,
        basic_username: ds.basic_username,
        basic_password: ds.basic_password,
      });
    }
  }

  onSubdomainChange = (e) => {
    this.setState({
      subdomain: e.target.value,
    });
  };

  onUsePasswordAuthenticationChange = (e) => {
    this.setState({
      use_password_authentication: e.target.value == 'userpassword',
    });
  };

  onUsernameChange = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  onBasicUsernameChange = (e) => {
    this.setState({
      basic_username: e.target.value,
    });
  };

  onBasicPasswordChange = (e) => {
    this.setState({
      basic_password: e.target.value,
    });
  };

  save = (e) => {
    e.preventDefault();

    var state = this.state;
    var datasource = {
      id: this.props.datasource.id,
      provider: state.provider,
      kintone_datasource_attributes: {
        id: state.child_id,
        subdomain: state.subdomain,
        basic_username: state.basic_username,
        basic_password: state.basic_password,
      },
    };
    var attr = datasource.kintone_datasource_attributes;

    if (state.use_password_authentication) {
      attr.username = state.username;
      attr.password = state.password;
    } else {
      attr.username = null;
      attr.password = null;
    }

    this.props.actions.saveDatasource(datasource);
  };

  render() {
    var isInvalid = (name) => {
      var obj = this.props.datasource.errors;
      if (obj && obj[name]) {
        return 'is-invalid';
      }
      return '';
    };

    return (
      <div className={styles.editContainer}>
        <h3>kintone 設定</h3>
        <form>
          <div className="form-group">
            <label htmlFor="datasource-subdomain" className="required">
              kintoneのURL（例： https://◯◯◯.cybozu.com）
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  https://
                </span>
              </div>
              <input
                type="text"
                className={`form-control ${isInvalid(
                  'kintone_datasource.subdomain',
                )}`}
                id="datasource-subdomain"
                value={this.state.subdomain || ''}
                onChange={this.onSubdomainChange}
              />
            </div>
          </div>

          <div className="form-group">
            <label>接続方式</label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="datasource-use-password-authentication"
                id="datasource-apitoken"
                value="apitoken"
                checked={!this.state.use_password_authentication}
                onChange={this.onUsePasswordAuthenticationChange}
              />
              <label className="form-check-label" htmlFor="datasource-apitoken">
                APIトークンで認証
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="datasource-use-password-authentication"
                id="datasource-userpassword"
                value="userpassword"
                checked={!!this.state.use_password_authentication}
                onChange={this.onUsePasswordAuthenticationChange}
              />
              <label
                className="form-check-label"
                htmlFor="datasource-userpassword"
              >
                ユーザー名とパスワードで認証
              </label>
            </div>
          </div>

          {this.state.use_password_authentication && (
            <div>
              <h4 className="mt3">認証情報</h4>
              <div className="form-group">
                <label htmlFor="datasource-username" className="required">
                  ユーザー名
                </label>
                <input
                  type="text"
                  className={`form-control ${isInvalid(
                    'kintone_datasource.username',
                  )}`}
                  id="datasource-username"
                  value={this.state.username || ''}
                  onChange={this.onUsernameChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="datasource-password" className="required">
                  パスワード
                </label>
                <input
                  type="password"
                  className={`form-control ${isInvalid(
                    'kintone_datasource.password',
                  )}`}
                  id="datasource-password"
                  value={this.state.password || ''}
                  onChange={this.onPasswordChange}
                />
              </div>
            </div>
          )}

          <h4 className="mt3">Basic認証</h4>
          <div className="form-group">
            <label htmlFor="datasource-basic-username" className="">
              Basic認証ユーザー名
            </label>
            <input
              type="text"
              className={`form-control ${isInvalid(
                'kintone_datasource.basic_username',
              )}`}
              id="datasource-basic-username"
              value={this.state.basic_username || ''}
              onChange={this.onBasicUsernameChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="datasource-basic-password" className="">
              Basic認証パスワード
            </label>
            <input
              type="password"
              className={`form-control ${isInvalid(
                'kintone_datasource.basic_password',
              )}`}
              id="datasource-basic-password"
              value={this.state.basic_password || ''}
              onChange={this.onBasicPasswordChange}
            />
          </div>

          <div className="mt-3 mb-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.save}
            >
              保存する
            </button>
          </div>
        </form>
      </div>
    );
  }
  componentDidMount() {}
}

export default Edit;
