import { combineReducers } from 'redux';
import * as types from './action_types.js';

function agent(state = {}, action = {}) {
  switch (action.type) {
    case types.RECEIVE_AGENT:
      return action.agent;
    default:
      return state;
  }
}

function done(state = false, action = {}) {
  switch (action.type) {
    case types.DONE:
      return true;
    default:
      return state;
  }
}

function errors(state = [], action = {}) {
  switch (action.type) {
    case types.DONE:
    case types.RECEIVE_AGENT:
      return [];
    case types.ERROR:
      return action.errors;
    default:
      return state;
  }
}

export default combineReducers({
  agent,
  done,
  errors,
});
