import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/webhook_notifications';
import Xhr from 'utils/xhr';
import Textarea from 'components/base/textarea';
import Condition from './condition';
import sortKintoneFields from 'utils/sortKintoneFields';

export const id = (function () {
  var count = 1;
  return function () {
    return '#' + count++;
  };
})();

const emptyCondition = () => {
  return {
    id: null,
    _id: id(),
    source: '',
  };
};

const emptyRecipient = () => {
  return {
    id: null,
    _id: id(),
    recipient_type: 'channel',
    recipient_code: '',
    field_code: '',
    recipient_code_type: 'recipient_code',
  };
};

const CONDITION_FIELD_TYPES = [
  'SINGLE_LINE_TEXT',
  'MULTI_LINE_TEXT',
  'RICH_TEXT',
  'RADIO_BUTTON',
  'DROP_DOWN',
  'STATUS',
  'LINK',
  'NUMBER',
  'CHECK_BOX',
  'MULTI_SELECT',
  'CREATOR',
  'MODIFIER',
  'USER_SELECT',
  'STATUS_ASSIGNEE',
  'ORGANIZATION_SELECT',
  'GROUP_SELECT',
  'DATE',
  'TIME',
  'DATETIME',
];

const DISPLAY_FIELD_TYPES = [
  'RECORD_NUMBER',
  'SINGLE_LINE_TEXT',
  'MULTI_LINE_TEXT',
  'RICH_TEXT',
  'RADIO_BUTTON',
  'DROP_DOWN',
  'STATUS',
  'LINK',
  'NUMBER',
  'CHECK_BOX',
  'MULTI_SELECT',
  'CREATOR',
  'MODIFIER',
  'USER_SELECT',
  'STATUS_ASSIGNEE',
  'ORGANIZATION_SELECT',
  'GROUP_SELECT',
  'DATE',
  'TIME',
  'CREATED_TIME',
  'UPDATED_TIME',
  'DATETIME',
  'FILE',
];

const RECIPIENT_FIELD_TYPES = ['SINGLE_LINE_TEXT'];

class Edit extends React.Component {
  static propTypes = {
    webhookNotification: PropTypes.object.isRequired,
    datasources: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.refMessageBody = React.createRef();

    const ws = props.webhookNotification;
    var state = {
      id: ws.id,
      name: ws.name,
      app_url: ws.app_url,
      api_token: ws.api_token,
      logical_statement: ws.logical_statement || 0,
      message_body: ws.message_body || '',
      notification_types: ws.notification_types,
      prevent_duplication: ws.prevent_duplication,
      show_link_to_comment: ws.show_link_to_comment,
      conditions:
        ws.conditions && ws.conditions.length > 0
          ? ws.conditions.map((c) => {
              return {
                id: c.id,
                _id: c.id ? null : id(),
                source: c.source,
                operator: c.operator,
                value: c.value,
              };
            })
          : [emptyCondition()],
      recipients:
        ws.recipients && ws.recipients.length > 0
          ? ws.recipients.map((r) => {
              return {
                id: r.id,
                _id: r.id ? null : id(),
                recipient_type: r.recipient_type,
                recipient_code: r.recipient_code,
                field_code: r.field_code,
                recipient_code_type: r.field_code
                  ? 'field_code'
                  : 'recipient_code',
              };
            })
          : [emptyRecipient()],
      kintone_app_settings: null,
      condition_fields: [],
      display_fields: [],
      recipient_fields: [],
    };
    this.state = state;
  }

  fetchAppSettings = () => {
    const url = 'agents/' + this.props.agent.id + '/kintone';
    const params = {
      app_url: this.state.app_url,
      datasource_id: this.getDatasource().id,
      api_token: this.state.api_token,
      status: 1,
      layout: 1,
    };
    const conditionFields = [];
    const displayFields = [
      {
        code: '#record_url',
        label: 'レコードのURL',
        type: null,
      },
      {
        code: '#app_url',
        label: 'アプリのURL',
        type: null,
      },
    ];
    const recipientFields = [];
    Xhr.execute(
      'GET',
      url,
      params,
      (data) => {
        const isProcessDisable =
          Object.values(data.fields.properties).findIndex((prop) => {
            return prop.type === 'STATUS' && prop.enabled === false;
          }) >= 0;
        const checkStatus = (prop, isProcessDisable) => {
          return !(
            isProcessDisable &&
            ['STATUS', 'STATUS_ASSIGNEE'].includes(prop.type)
          );
        };
        const fields = sortKintoneFields(data.fields.properties, data.layout);
        fields.forEach((prop) => {
          const field = {
            code: prop.code,
            label: prop.label,
            type: prop.type,
          };
          if (prop.options) {
            field.options = Object.values(prop.options)
              .sort((a, b) => {
                return a.index - b.index;
              })
              .map((option) => {
                return option.label;
              });
          } else if (prop.type === 'STATUS' && !isProcessDisable) {
            field.options = Object.values(data.status.states)
              .sort((a, b) => {
                return a.index - b.index;
              })
              .map((option) => {
                return option.name;
              });
          }
          if (
            CONDITION_FIELD_TYPES.indexOf(prop.type) >= 0 &&
            checkStatus(prop, isProcessDisable)
          ) {
            conditionFields.push(field);
          }
          if (
            DISPLAY_FIELD_TYPES.indexOf(prop.type) >= 0 &&
            checkStatus(prop, isProcessDisable)
          ) {
            displayFields.push(field);
          }
          if (RECIPIENT_FIELD_TYPES.indexOf(prop.type) >= 0) {
            recipientFields.push(field);
          }
        });

        conditionFields.push({
          code: '#comment_mentions',
          label: 'コメントの宛先',
          type: 'COMMENT_MENTION',
        });
        const conditions = [...this.state.conditions];
        conditions.forEach((condition, i) => {
          if (
            !condition.source ||
            !conditionFields.find((field) => {
              return field.code === condition.source;
            })
          ) {
            conditions[i] = emptyCondition();
          }
        });
        const recipients = [...this.state.recipients];
        recipients.forEach((recipient, i) => {
          if (
            recipient.field_code &&
            !recipientFields.find((field) => {
              return field.code === recipient.field_code;
            })
          ) {
            recipients[i] = emptyRecipient();
          }
        });
        this.setState({
          conditions,
          recipients,
          kintone_app_settings: data,
          condition_fields: conditionFields,
          display_fields: displayFields,
          recipient_fields: recipientFields,
        });
        this.props.actions.error([]);
      },
      (xhr, status, err) => {
        this.setState(
          {
            kintone_app_settings: null,
          },
          () => {
            this.props.actions.error(
              ['kintoneからの情報取得に失敗しました。'],
              {},
            );
          },
        );
      },
    );
  };

  onNextClick = (e) => {
    if (this.state.app_url) {
      if (this.isValidAppUrl(this.state.app_url)) {
        this.fetchAppSettings();
      } else {
        this.props.actions.error(['アプリのURLが不正です。'], {
          app_url: true,
        });
      }
    }
  };

  getDatasource = () => {
    let datasource = null;
    if (this.props.webhookNotification.datasource) {
      datasource = this.props.datasources.find((ds) => {
        return ds.id === this.props.webhookNotification.datasource.id;
      });
    }
    if (!datasource) {
      datasource = this.props.datasources[0];
    }
    return datasource;
  };

  destroyedAllConditions = (conditions) => {
    const newFields = fields
      .filter((field) => {
        return field.field_code;
      })
      .map((field) => {
        const newField = { ...field };
        newField._destroy = true;
        return newField;
      });
    newFields.push(getEmptyField());
    return newFields;
  };

  getFormats = () => {
    const displayFields = this.state.message_body.match(/{{([^}]+)}}/g) || [];
    const formats = {};
    displayFields.forEach((code) => {
      const field = this.state.kintone_app_settings.fields.properties[code];
      if (field && field.type == 'NUMBER') {
        formats[code] = {
          digit: field.digit,
          displayScale: field.displayScale,
          unit: field.unit,
          unitPosition: field.unitPosition,
        };
      }
    });
    return formats;
  };

  save = (e) => {
    e.preventDefault();
    if (!this.state.kintone_app_settings) {
      return;
    }
    const normalizeValue = (condition) => {
      let value = condition.value;
      if (['include', 'not_include'].includes(condition.operator)) {
        try {
          let values = JSON.parse(value);
          if (condition.source === '#comment_mentions') {
            values = values.filter((v) => v && !v.trim().endsWith(':'));
          }
          value = JSON.stringify(values);
        } catch (e) {
          value = '[]';
        }
      }
      return value;
    };

    const ds = this.getDatasource();
    const is_token_auth =
      ds && ds.kintone && !ds.kintone.use_password_authentication;
    const webhookNotification = {
      id: this.props.webhookNotification.id,
      name: this.state.name,
      datasource_id: ds.id,
      app_url: this.state.app_url,
      api_token: is_token_auth ? this.state.api_token : null,
      notification_types: this.state.notification_types,
      logical_statement: this.state.logical_statement,
      message_body: this.state.message_body,
      formats: JSON.stringify(this.getFormats()),
      prevent_duplication: this.state.prevent_duplication,
      show_link_to_comment: this.state.show_link_to_comment,
      conditions_attributes: this.state.conditions
        .filter((c) => c.source)
        .map((condition) => {
          const field = this.state.condition_fields.find((field) => {
            return field.code === condition.source;
          });
          return {
            id: condition.id,
            source: condition.source,
            source_type: field ? field.type : null,
            operator: condition.operator,
            value: normalizeValue(condition),
          };
        }),
      recipients_attributes: this.state.recipients.map((recipient) => {
        return {
          id: recipient.id,
          recipient_type: recipient.recipient_type,
          recipient_code: recipient.recipient_code,
          field_code: recipient.field_code,
        };
      }),
    };
    this.props.actions.saveWebhookNotification(webhookNotification, true);
  };

  isValidAppUrl(url) {
    return url.match(/^.*\/k\/(guest\/(\d+)\/)?(\d+)/);
  }

  cancel = () => {
    this.props.actions.clearWebhookNotification(true);
  };

  renderNotificationTypes = () => {
    return (
      <div className="mb-5">
        <h4>通知の種類</h4>
        <div className="form-check mb-2">
          <input
            id="webhook-notification-types-add-record"
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={this.state.notification_types & 1}
            onChange={this.onNotificationTypesChange}
          />
          <label
            className="form-check-label"
            htmlFor="webhook-notification-types-add-record"
          >
            レコードの追加
          </label>
        </div>
        <div className="form-check mb-2">
          <input
            id="webhook-notification-types-update-record"
            className="form-check-input"
            type="checkbox"
            value="2"
            checked={this.state.notification_types & 2}
            onChange={this.onNotificationTypesChange}
          />
          <label
            className="form-check-label"
            htmlFor="webhook-notification-types-update-record"
          >
            レコードの更新
          </label>
        </div>
        <div className="form-check mb-2">
          <input
            id="webhook-notification-types-update-status"
            className="form-check-input"
            type="checkbox"
            value="4"
            checked={this.state.notification_types & 4}
            onChange={this.onNotificationTypesChange}
          />
          <label
            className="form-check-label"
            htmlFor="webhook-notification-types-update-status"
          >
            プロセス管理のステータスの更新
          </label>
        </div>
        <div className="form-check mb-2">
          <input
            id="webhook-notification-types-add-comment"
            className="form-check-input"
            type="checkbox"
            value="8"
            checked={this.state.notification_types & 8}
            onChange={this.onNotificationTypesChange}
          />
          <label
            className="form-check-label"
            htmlFor="webhook-notification-types-add-comment"
          >
            コメントの書き込み
          </label>
        </div>
      </div>
    );
  };

  renderConditions = () => {
    return (
      <div className="mb-5">
        <h4>通知する条件</h4>
        <div className="mb-3">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="webhook-nofitications-logical-statement"
              value="statement_and"
              id="webhook-nofitications-logical-statement-and"
              checked={this.state.logical_statement != 'statement_or'}
              onChange={this.onLogicalStatementChange}
            />
            <label
              className="form-check-label"
              htmlFor="webhook-nofitications-logical-statement-and"
            >
              すべての条件を満たす
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="webhook-nofitications-logical-statement"
              value="statement_or"
              id="webhook-nofitications-logical-statement-or"
              checked={this.state.logical_statement == 'statement_or'}
              onChange={this.onLogicalStatementChange}
            />
            <label
              className="form-check-label"
              htmlFor="webhook-nofitications-logical-statement-or"
            >
              いずれかの条件を満たす
            </label>
          </div>
        </div>
        <div className="mb-3">
          コメントの書き込み通知は、条件「コメントの宛先」のみが適用されます。
        </div>
        <div className="list-group mb-3">
          {this.state.conditions.map((condition, index) => {
            return (
              <Condition
                key={condition.id || condition._id}
                condition={condition}
                conditionFields={this.state.condition_fields}
                onChange={(condition) => {
                  this.onConditionChange(condition, index);
                }}
                onRemove={() => {
                  this.onConditionRemove(index);
                }}
              />
            );
          })}
        </div>
        <div>
          <a href="#!" onClick={this.onConditionAdd}>
            <i className="material-icons mr-1">add_circle</i>
            <span>条件を追加する</span>
          </a>
        </div>
      </div>
    );
  };

  renderRecipients = () => {
    return (
      <div className="mb-5">
        <h4>通知先</h4>
        <div className="list-group mb-3">
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-sm-4">種別</div>
              <div className="col-sm-8">チャンネルID/ユーザーID</div>
            </div>
          </div>
          {this.state.recipients.map((recipient, index) => {
            return (
              <div
                key={recipient.id || recipient._id}
                className="list-group-item list-group-item-action"
              >
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <select
                      className="form-control"
                      value={recipient.recipient_type}
                      onChange={(e) => {
                        this.onRecipientTypeChange(e, index);
                      }}
                    >
                      <option value="channel">チャンネル</option>
                      <option value="user">ユーザー</option>
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control"
                      value={recipient.recipient_code_type}
                      onChange={(e) => {
                        this.onRecipientCodeTypeChange(e, index);
                      }}
                    >
                      <option value="recipient_code">直接入力</option>
                      <option value="field_code">kintoneのフィールド値</option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    {recipient.recipient_code_type === 'field_code' ? (
                      <select
                        className="form-control"
                        value={recipient.field_code}
                        onChange={(e) => {
                          this.onFieldCodeChange(e, index);
                        }}
                      >
                        <option value=""></option>
                        {this.state.recipient_fields.map((field) => {
                          return (
                            <option key={field.code} value={field.code}>
                              {field.label}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        value={recipient.recipient_code}
                        onChange={(e) => {
                          this.onRecipientCodeChange(e, index);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-sm-1">
                    <span className={styles.buttons}>
                      {this.state.recipients.length > 1 && (
                        <a
                          href="#!"
                          onClick={(e) => {
                            this.onRecipientRemove(e, index);
                          }}
                        >
                          <i className="material-icons mr-1">remove_circle</i>
                        </a>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <a href="#!" onClick={this.onRecipientAdd}>
            <i className="material-icons mr-1">add_circle</i>
            <span>通知先を追加する</span>
          </a>
        </div>
      </div>
    );
  };

  renderMessageBody = () => {
    const insertField = (field) => {
      const placeholder = `{{${field.code}}}`;
      const text = this.state.message_body || '';
      const pos = this.refMessageBody.current.selectionStart;
      const before = text.substring(0, pos);
      const after = text.substring(pos);
      const messageBody = before + placeholder + after;
      this.setState({ message_body: messageBody }, () => {
        this.refMessageBody.current.focus();
        this.refMessageBody.current.setSelectionRange(
          pos + placeholder.length,
          pos + placeholder.length,
        );
      });
    };
    return (
      <div className="mb-5">
        <h4>通知メッセージ</h4>
        <div>
          <Textarea
            ref={this.refMessageBody}
            maxRows={15}
            minRows={5}
            className={`form-control ${styles.messageBodyTextarea}`}
            name="message_body"
            value={this.state.message_body || ''}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.displayFields}>
          {this.state.display_fields.map((field) => {
            return (
              <button
                type="button"
                key={field.code}
                onClick={() => {
                  insertField(field);
                }}
                className={styles.displayField}
              >
                {field.label}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  renderOthers = () => {
    return (
      <div className="mb-5">
        <div className="form-check mb-2">
          <input
            id="prevent_duplication"
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={this.state.prevent_duplication || false}
            onChange={this.onPreventDuplicationChange}
          />
          <label className="form-check-label" htmlFor="prevent_duplication">
            同じ条件で重複して通知しない
          </label>
        </div>
        {!!(this.state.notification_types & 8) && (
          <div className="form-check mb-2">
            <input
              id="show_link_to_comment"
              className="form-check-input"
              type="checkbox"
              value="1"
              checked={this.state.show_link_to_comment || false}
              onChange={this.onShowLinkToCommentChange}
            />
            <label className="form-check-label" htmlFor="show_link_to_comment">
              コメントの通知にリンクを表示
            </label>
          </div>
        )}
      </div>
    );
  };

  renderSettings = () => {
    return (
      <div className="mt-3">
        {this.renderNotificationTypes()}
        {this.renderConditions()}
        {this.renderRecipients()}
        {this.renderMessageBody()}
        {this.renderOthers()}
      </div>
    );
  };

  render() {
    const isInvalid = (name) => {
      if (
        this.props.webhookNotification.errors &&
        this.props.webhookNotification.errors[name]
      ) {
        return 'is-invalid';
      }
      return '';
    };

    const ds = this.getDatasource();

    return (
      <div className={styles.mainBody}>
        <h3>更新通知の設定</h3>
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="webhook-notification-name" className="required">
                名称
              </label>
              <input
                type="text"
                className={`form-control ${isInvalid('name')}`}
                id="webhook-notification-name"
                value={this.state.name || ''}
                name="name"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                htmlFor="webhook-notification-app-url"
                className="required"
              >
                アプリのURL
              </label>
              <input
                type="text"
                name="app_url"
                className={`form-control ${isInvalid('app_url')}`}
                id="webhook-notification-app-url"
                placeholder={`https://${ds.kintone.subdomain}/k/1`}
                value={this.state.app_url || ''}
                onChange={this.onAppUrlChange}
              />
            </div>
            {ds && ds.kintone && !ds.kintone.use_password_authentication && (
              <div className="form-group col-md-6">
                <label
                  htmlFor="webhook-notification-api-token"
                  className="required"
                >
                  APIトークン
                </label>
                <input
                  type="text"
                  name="api_token"
                  className={`form-control ${isInvalid('api_token')}`}
                  id="webhook-notification-api-token"
                  value={this.state.api_token || ''}
                  onChange={this.onApiTokenChange}
                />
              </div>
            )}
          </div>
          {this.state.kintone_app_settings ? (
            <div className="mt-3">
              {this.renderSettings()}
              <div className="mt-3 mb-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.save}
                >
                  保存する
                </button>
                <button
                  className="ml-2 btn"
                  type="button"
                  onClick={this.cancel}
                >
                  キャンセル
                </button>
              </div>
            </div>
          ) : (
            <div className="mt-3 mb-3">
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.onNextClick}
              >
                次に、条件を設定する
              </button>
              <button className="ml-2 btn" type="button" onClick={this.cancel}>
                キャンセル
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  };

  onAppUrlChange = (e) => {
    this.setState({
      app_url: e.target.value,
      kintone_app_settings: null,
      conditions: [emptyCondition()],
    });
  };

  onApiTokenChange = (e) => {
    this.setState({
      api_token: e.target.value,
      kintone_app_settings: null,
    });
  };

  onNotificationTypesChange = (e) => {
    let notification_types = this.state.notification_types;
    const type = parseInt(e.target.value);
    if (e.target.checked) {
      notification_types |= type;
    } else {
      notification_types &= ~type;
    }
    this.setState({ notification_types });
  };

  onLogicalStatementChange = (e) => {
    this.setState({ logical_statement: e.target.value });
  };

  onConditionChange = (condition, index) => {
    const conditions = [...this.state.conditions];
    conditions[index] = { ...condition };
    this.setState({ conditions });
  };

  onConditionAdd = (e) => {
    e.preventDefault();
    const conditions = [...this.state.conditions];
    conditions.push(emptyCondition());
    this.setState({ conditions });
  };

  onConditionRemove = (index) => {
    const conditions = [...this.state.conditions];
    if (conditions.length == 0) {
      return;
    }
    conditions.splice(index, 1);
    this.setState({ conditions });
  };

  onRecipientTypeChange = (e, index) => {
    const recipients = [...this.state.recipients];
    recipients[index].recipient_type = e.target.value;
    this.setState({ recipients });
  };

  onRecipientCodeChange = (e, index) => {
    const recipients = [...this.state.recipients];
    recipients[index].recipient_code = e.target.value;
    this.setState({ recipients });
  };

  onFieldCodeChange = (e, index) => {
    const recipients = [...this.state.recipients];
    recipients[index].field_code = e.target.value;
    this.setState({ recipients });
  };

  onRecipientCodeTypeChange = (e, index) => {
    const recipients = [...this.state.recipients];
    recipients[index] = {
      ...recipients[index],
      recipient_code: '',
      field_code: '',
      recipient_code_type: e.target.value,
    };
    this.setState({ recipients });
  };

  onRecipientAdd = (e) => {
    e.preventDefault();
    const recipients = [...this.state.recipients];
    recipients.push(emptyRecipient());
    this.setState({ recipients });
  };

  onRecipientRemove = (e, index) => {
    e.preventDefault();
    const recipients = [...this.state.recipients];
    if (recipients.length == 1) {
      return;
    }
    recipients.splice(index, 1);
    this.setState({ recipients });
  };

  onPreventDuplicationChange = (e) => {
    this.setState({ prevent_duplication: e.target.checked });
  };

  onShowLinkToCommentChange = (e) => {
    this.setState({ show_link_to_comment: e.target.checked });
  };

  componentDidMount() {
    if (this.state.app_url) {
      if (this.isValidAppUrl(this.state.app_url)) {
        this.fetchAppSettings();
      }
    }
  }
}

export default Edit;
