import { combineReducers } from 'redux';
import * as types from './action_types.js';

function agents(state = [], action = {}) {
  return state;
}

function datasources(state = [], action = {}) {
  return state;
}

function agent(state = {}, action = {}) {
  return state;
}

function scenarios(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_SCENARIOS:
      return action.scenarios;
    default:
      return state;
  }
}

function scenario(state = null, action = {}) {
  switch (action.type) {
    case types.RECEIVE_SCENARIO:
      return action.scenario;
    case types.ERROR:
      if (state && $.isPlainObject(state)) {
        state.errors = action.errorObj;
      }
      return state;
    case types.RECEIVE_SCENARIOS:
      return null;
    default:
      return state;
  }
}

function errors(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_SCENARIOS:
    case types.RECEIVE_SCENARIO:
      return [];
    case types.ERROR:
      return action.errors;
    default:
      return state;
  }
}

function alerts(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_SCENARIOS:
    case types.RECEIVE_SCENARIO:
      return [];
    case types.ALERT:
      return action.alerts;
    default:
      return state;
  }
}

export default combineReducers({
  agents,
  datasources,
  agent,
  scenarios,
  scenario,
  errors,
  alerts,
});
