import Xhr from 'utils/xhr.js';
import Modal from 'utils/modal';
import * as types from './action_types.js';

export function done(scenario) {
  return {
    type: types.DONE,
  };
}

export function deploy(scenario) {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = `agents/${state.agent.id}/deploy`;
    method = 'PATCH';

    Xhr.execute(
      method,
      url,
      scenario,
      (data) => {
        Modal.toast('alert', '設定が反映されました。');
        dispatch(done());
      },
      (xhr, status, err) => {
        const errors = xhr.responseJSON;
        Modal.toast(
          'alert',
          Array.isArray(errors) && errors.length > 0
            ? errors[0]
            : '設定の反映に失敗しました。',
        );
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
}

export const error = (errors, errorObj) => {
  return {
    type: types.ERROR,
    errors,
    errorObj,
  };
};
