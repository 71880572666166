const UaUtil = {
  isMobileOrTablet: () => {
    if (typeof UAParser === 'undefined') return false;

    var parser = new UAParser();
    var os = parser.getOS();
    return (
      os.name === 'iOS' ||
      os.name === 'Android' ||
      app.data.isMobile ||
      UaUtil.isMobileApp()
    );
  },
  isIe: () => {
    if (typeof UAParser === 'undefined') return false;

    var parser = new UAParser();
    var browser = parser.getBrowser().name;
    return browser === 'IE';
  },
};

export default UaUtil;
