import React from 'react';
import PropTypes from 'prop-types';

class Icon extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    class: PropTypes.string,
  };

  render() {
    var className = 'material-icons mr-1';
    if (this.props.class) {
      className += ' ' + this.props.class;
    }
    return <i className={className}>{this.props.name}</i>;
  }
}

export default Icon;
