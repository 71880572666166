import App from 'components/webhook_notifications/container';
import reducer from 'components/webhook_notifications/reducers';
import renderer from 'controllers/react_renderer';

window.app.pages.WebhookNotificationsIndex = function () {
  renderer.render(reducer, App, 'react-container', {
    agents: app.data.settings.agents,
    agent: app.data.settings.agent,
    datasources: app.data.datasources,
    webhookNotifications: app.data.webhookNotifications,
  });
};
