import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/scenarios';
import Xhr from 'utils/xhr';
import TagEditor from 'components/base/tag_editor';
import Alert from 'components/base/alerts';
import Spinner from 'utils/spinner';
import { getFixedSetFieldsFromKintoneFields } from 'utils/get_fixed_set_fields_from_kintone_fields';
import { getFormatByKintoneField } from 'utils/get_format_by_kintone_field';
import { getOptionsByKintoneField } from 'utils/get_options_by_kintone_field';
import Icon from 'components/base/icon';
import SelectListItem from './select_list_item';
import QueryListItem from './query_list_item';
import SortListItem from './sort_list_item';
import InputListItem from './input_list_item';
import UpdateListItem from './update_list_item';
import InputItem from './input_item';
import SelectItem from './select_item';
import sortKintoneFields from 'utils/sortKintoneFields';

export const id = (function () {
  var count = 1;
  return function () {
    return '#' + count++;
  };
})();

const getEmptyField = () => {
  return {
    id: id(),
    field_code: '',
  };
};

const QUERY_FIELD_TYPES = [
  'SINGLE_LINE_TEXT',
  'MULTI_LINE_TEXT',
  'RICH_TEXT',
  'RADIO_BUTTON',
  'DROP_DOWN',
  'CHECK_BOX',
  'MULTI_SELECT',
  'DATE',
  'DATETIME',
];
const SELECT_FIELD_TYPES = [
  'SINGLE_LINE_TEXT',
  'MULTI_LINE_TEXT',
  'RICH_TEXT',
  'RECORD_NUMBER',
  'RADIO_BUTTON',
  'DROP_DOWN',
  'STATUS',
  'LINK',
  'NUMBER',
  'CHECK_BOX',
  'MULTI_SELECT',
  'CREATOR',
  'MODIFIER',
  'USER_SELECT',
  'STATUS_ASSIGNEE',
  'ORGANIZATION_SELECT',
  'GROUP_SELECT',
  'DATE',
  'TIME',
  'CREATED_TIME',
  'UPDATED_TIME',
  'DATETIME',
  'FILE',
];
const SORT_FIELD_TYPES = [
  'SINGLE_LINE_TEXT',
  'MULTI_LINE_TEXT',
  'RICH_TEXT',
  'RECORD_NUMBER',
  'RADIO_BUTTON',
  'DROP_DOWN',
  'STATUS',
  'LINK',
  'NUMBER',
  'CHECK_BOX',
  'MULTI_SELECT',
  'CREATOR',
  'MODIFIER',
  'USER_SELECT',
  'STATUS_ASSIGNEE',
  'ORGANIZATION_SELECT',
  'GROUP_SELECT',
  'DATE',
  'TIME',
  'CREATED_TIME',
  'UPDATED_TIME',
  'DATETIME',
];

const INPUT_FIELD_TYPES = [
  'SINGLE_LINE_TEXT',
  'MULTI_LINE_TEXT',
  'RICH_TEXT',
  'RADIO_BUTTON',
  'DROP_DOWN',
  'LINK',
  'NUMBER',
  'CHECK_BOX',
  'MULTI_SELECT',
  'USER_SELECT',
  'CREATOR',
  'MODIFIER',
  //'ORGANIZATION_SELECT',
  //'GROUP_SELECT',
  'DATE',
  'TIME',
  'DATETIME',
  'FILE',
];

const UPDATE_FIELD_TYPES = [
  'SINGLE_LINE_TEXT',
  'MULTI_LINE_TEXT',
  'RICH_TEXT',
  'LINK',
  'NUMBER',
  'DATE',
  'TIME',
  'DATETIME',
  'FILE',
  'RADIO_BUTTON',
  'DROP_DOWN',
  'USER_SELECT',
];

class Edit extends React.Component {
  static propTypes = {
    scenario: PropTypes.object.isRequired,
    datasources: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    var scenario = this.props.scenario;

    var queries = scenario.queries;
    if (queries.length == 0) {
      queries.push(getEmptyField());
    }
    var inputFields = scenario.input_fields;
    if (inputFields.length == 0) {
      inputFields.push(getEmptyField());
    }
    var selectFields = scenario.select_fields;
    if (selectFields.length == 0) {
      selectFields.push(getEmptyField());
    }
    var sortFields = scenario.sort_fields;
    if (sortFields.length == 0) {
      sortFields.push(getEmptyField());
    }

    var updateFields = scenario.update_fields;
    if (updateFields.length == 0) {
      updateFields.push(getEmptyField());
    }

    var state = {
      datasource_id: scenario.datasource?.id,
      app_url: scenario.app_url,
      api_token: scenario.api_token,
      name: scenario.name,
      wakeup_word: scenario.wakeup_word,
      show_link: scenario.show_link,
      persistent_menu: scenario.persistent_menu,
      is_update: scenario.is_update,
      scenario_type: scenario.scenario_type,
      queries: queries,
      initial_query: scenario.initial_query,
      select_fields: selectFields,
      input_fields: inputFields,
      sort_fields: sortFields,
      update_fields: updateFields,
      query_field_options: [],
      input_field_options: [],
      select_field_options: [],
      sort_field_options: [],
      update_field_options: [],
      initial_query_list: [],
      kintone_loaded: false,
      app_url_changed: false,
      initial_query_view_name: scenario.initial_query_view_name,
      initial_query_input: scenario.initial_query_input,
      condition_initial_query: !!scenario.initial_query_view_name,
      use_scheduled_notification: scenario.use_scheduled_notification,
      lookupAppForms: {},
      lookupFieldMappings: {},
      lookupAppCount: 0,
      lookupSpinner: null,
    };

    if (!state.datasource_id && this.props.datasources.length > 0) {
      state.datasource_id = this.props.datasources[0].id;
    }
    this.state = state;
  }

  getDatasource = () => {
    for (var i = 0; i < this.props.datasources.length; i++) {
      var ds = this.props.datasources[i];
      if (this.state.datasource_id == ds.id) {
        return ds;
      }
    }
    return null;
  };

  getDestroyedFields = (fields) => {
    const newFields = fields
      .filter((field) => {
        return field.field_code;
      })
      .map((field) => {
        const newField = { ...field };
        newField._destroy = true;
        return newField;
      });
    newFields.push(getEmptyField());
    return newFields;
  };

  getLookupDisplayFields = (opt) => {
    const lookupAppProps =
      this.state.lookupAppForms[opt.lookup.relatedApp.app].fields.properties;
    const res = opt.lookup.lookupPickerFields.slice();
    res.unshift(opt.lookup.relatedKeyField);
    return res
      .filter((v, i, self) => self.indexOf(v) === i)
      .map((v) => {
        return {
          field_code: v,
          field_label: lookupAppProps[v].label,
        };
      });
  };

  getLookupAppForm = (app) => {
    const lookupAppForms = this.state.lookupAppForms;
    const url = 'agents/' + this.props.agent.id + '/kintone';
    const m = this.state.app_url.match(/.*\/k\/(guest\/(\d+)\/)?/);
    const appUrl = m[0] + app;
    const params = {
      app_url: appUrl,
      datasource_id: this.state.datasource_id,
      api_token: this.state.api_token,
    };
    Xhr.execute(
      'GET',
      url,
      params,
      (data) => {
        lookupAppForms[app] = data;
        let lookupAppCount = this.state.lookupAppCount - 1;
        let lookupSpinner = this.state.lookupSpinner;
        if (lookupAppCount === 0 && lookupSpinner) {
          lookupSpinner.stop();
          lookupSpinner = null;
        }
        this.setState({
          lookupAppForms: lookupAppForms,
          lookupSpinner: lookupSpinner,
          lookupAppCount: lookupAppCount,
        });
      },
      (xhr, status, err) => {
        this.state.lookupSpinner.stop();
        this.setState(
          {
            lookupSpinner: null,
            kintone_loaded: false,
          },
          () => {
            this.props.actions.error(
              ['kintoneからの情報取得に失敗しました。'],
              {},
            );
          },
        );
      },
      true,
    );
  };

  fetchAppSettings = (isResetField = false) => {
    const url = 'agents/' + this.props.agent.id + '/kintone';
    const params = {
      app_url: this.state.app_url,
      datasource_id: this.state.datasource_id,
      api_token: this.state.api_token,
      initial_query: 1,
      layout: 1,
    };
    const inputFields = [];
    const queryFields = [];
    const selectFields = [];
    const sortFields = [];
    const updateFields = [];
    const initial_query_list = [];

    Xhr.execute(
      'GET',
      url,
      params,
      (data) => {
        const state = {
          kintone_loaded: true,
        };
        const lookupFieldMappings = {};
        const lookupApps = new Set();
        const isProcessDisable =
          Object.values(data.fields.properties).findIndex((prop) => {
            return prop.type === 'STATUS' && prop.enabled === false;
          }) >= 0;
        const checkStatus = (prop, isProcessDisable) => {
          return !(
            isProcessDisable &&
            ['STATUS', 'STATUS_ASSIGNEE'].includes(prop.type)
          );
        };
        let spinner = null;
        const fields = sortKintoneFields(data.fields.properties, data.layout);
        fields.forEach((prop) => {
          if (QUERY_FIELD_TYPES.indexOf(prop.type) >= 0) {
            queryFields.push(prop);
          }
          if (
            SELECT_FIELD_TYPES.indexOf(prop.type) >= 0 &&
            checkStatus(prop, isProcessDisable)
          ) {
            selectFields.push(prop);
          }
          if (
            SORT_FIELD_TYPES.indexOf(prop.type) >= 0 &&
            checkStatus(prop, isProcessDisable)
          ) {
            sortFields.push(prop);
          }
          if (INPUT_FIELD_TYPES.indexOf(prop.type) >= 0) {
            inputFields.push(prop);
          }
          if (UPDATE_FIELD_TYPES.indexOf(prop.type) >= 0) {
            updateFields.push(prop);
          }
          if (prop.lookup) {
            prop.lookup.fieldMappings.forEach((mapping) => {
              lookupFieldMappings[mapping.field] = {
                app: prop.lookup.relatedApp.app,
                field: mapping.relatedField,
              };
            });
            lookupApps.add(prop.lookup.relatedApp.app);
          }
        });
        if (lookupApps.size !== 0) {
          spinner = new Spinner();
        }
        if (data.initial_query) {
          Object.keys(data.initial_query.views).forEach((query) => {
            initial_query_list.push({
              name: data.initial_query.views[query].name,
              custom: {
                'data-query': data.initial_query.views[query].filterCond,
              },
            });
          });
        }

        let flagOfChangedAlert = false;
        let updateState = {};
        const modification = [];
        if (this.state.scenario_type === 'general_search' && !isResetField) {
          const fixedQueryFields = getFixedSetFieldsFromKintoneFields(
            'queries',
            this.state.queries,
            queryFields,
            getEmptyField,
          );
          const fixedUpdateFields = getFixedSetFieldsFromKintoneFields(
            'update_fields',
            this.state.update_fields,
            updateFields,
            getEmptyField,
          );
          const fixedSortFields = getFixedSetFieldsFromKintoneFields(
            'sort_fields',
            this.state.sort_fields,
            sortFields,
            getEmptyField,
          );
          const fixedSelectFields = getFixedSetFieldsFromKintoneFields(
            'select_fields',
            this.state.select_fields,
            selectFields,
            getEmptyField,
          );
          updateState = {
            ...updateState,
            ...fixedQueryFields.changedFields,
            ...fixedUpdateFields.changedFields,
            ...fixedSortFields.changedFields,
            ...fixedSelectFields.changedFields,
          };
          if (
            fixedQueryFields.alertFlag ||
            fixedUpdateFields.alertFlag ||
            fixedSortFields.alertFlag ||
            fixedSelectFields.alertFlag
          ) {
            modification.push('フォーム');
            flagOfChangedAlert = true;
          }
        }

        if (this.state.scenario_type === 'general_register' && !isResetField) {
          const fixedInputFields = getFixedSetFieldsFromKintoneFields(
            'input_fields',
            this.state.input_fields,
            inputFields,
            getEmptyField,
          );
          updateState = {
            ...updateState,
            ...fixedInputFields.changedFields,
          };
          if (fixedInputFields.alertFlag) {
            modification.push('フォーム');
            flagOfChangedAlert = true;
          }
        }

        if (this.state.condition_initial_query && !isResetField) {
          const fixedInitialQuery =
            this.getFixedInitialQuery(initial_query_list);
          updateState = {
            ...updateState,
            ...fixedInitialQuery.changedFields,
          };
          if (fixedInitialQuery.alertFlag) {
            flagOfChangedAlert = true;
            modification.push('一覧');
          }
        }

        if (isResetField) {
          updateState = {
            ...updateState,
            queries: this.getDestroyedFields(this.state.queries),
            update_fields: this.getDestroyedFields(this.state.update_fields),
            select_fields: this.getDestroyedFields(this.state.select_fields),
            sort_fields: this.getDestroyedFields(this.state.sort_fields),
            input_fields: this.getDestroyedFields(this.state.input_fields),
            initial_query: null,
            initial_query_view_name: null,
          };
        }

        if (flagOfChangedAlert) {
          const modificationText = modification.join('、');
          this.props.actions.alert(
            [
              `kintoneの${modificationText}設定が変更されています。再度シナリオの保存とチャットボットへの反映を行ってください。`,
            ],
            {},
          );
        }

        this.setState({
          ...state,
          ...updateState,
          input_field_options: inputFields,
          query_field_options: queryFields,
          select_field_options: selectFields,
          sort_field_options: sortFields,
          update_field_options: updateFields,
          initial_query_list: initial_query_list,
          app_url_changed: false,
          lookupAppCount: lookupApps.size,
          lookupSpinner: spinner,
          lookupFieldMappings: lookupFieldMappings,
        });
        lookupApps.forEach((appId) => {
          this.getLookupAppForm(appId);
        });
        this.props.actions.error([]);
      },
      (xhr, status, err) => {
        this.setState(
          {
            kintone_loaded: false,
          },
          () => {
            this.props.actions.error(
              ['kintoneからの情報取得に失敗しました。'],
              {},
            );
          },
        );
      },
    );
  };

  getFixedInitialQuery = (initial_query_list) => {
    let flag = false;
    let found = false;
    let view_name;
    let query;
    const res = {
      changedFields: {},
      alertFlag: false,
    };
    initial_query_list.map((initial_query) => {
      if (this.state.initial_query_view_name === initial_query.name) {
        found = true;
        view_name = initial_query.name;
        query = initial_query.custom['data-query'];
        flag = !!(
          this.state.initial_query === initial_query.custom['data-query']
        );
      }
    });
    if (!found) {
      flag = false;
      query = null;
      view_name = null;
    }

    if (!flag) {
      res.changedFields = {
        initial_query: query,
        initial_query_view_name: view_name,
      };
      if (!found || !(query === '' && this.state.initial_query == null)) {
        res.alertFlag = true;
      }
    }
    return res;
  };

  onNextClick = (e) => {
    if (this.state.app_url) {
      if (this.isValidAppUrl(this.state.app_url)) {
        this.fetchAppSettings(this.state.app_url_changed);
      } else {
        this.props.actions.error(['アプリのURLが不正です。'], {
          app_url: true,
        });
      }
    }
  };

  getPhrase() {
    var name = this.state.wakeup_word;
    if (name) {
      name = name.split('\n')[0];
    }
    if (name) {
      switch (this.state.scenario_type) {
        case 'general_search':
          return `${name}を検索, ◯◯で${name}を検索, ◯◯の${name}を教えて, ◯◯の${name}`;
        case 'general_register':
          return `${name}を登録, ${name}を追加,${name}を作成`;
      }
    }
    return '';
  }

  onChangeInitialQueryRadio = () => {
    this.setState((prevState) => ({
      initial_query: null,
      initial_query_view_name: null,
      condition_initial_query: !prevState.condition_initial_query,
    }));
  };
  onAppUrlChange = (e) => {
    this.setState({
      app_url: e.target.value,
      kintone_loaded: false,
      app_url_changed: true,
    });
  };

  onApiTokenChange = (e) => {
    this.setState({
      api_token: e.target.value,
      kintone_loaded: false,
    });
  };

  onNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  onWakeupWordChange = (tags) => {
    this.setState({
      wakeup_word: tags.join('\n'),
    });
  };

  onShowLinkChange = (e) => {
    this.setState({ show_link: $(e.target).prop('checked') });
  };

  onPersistentMenuChange = (e) => {
    this.setState({ persistent_menu: $(e.target).prop('checked') });
  };

  onUpdateChange = (e) => {
    this.setState({ is_update: $(e.target).prop('checked') });
  };

  onUseScheduledNotificationChange = (e) => {
    this.setState({ use_scheduled_notification: $(e.target).prop('checked') });
  };

  onChangeInitialQueryInput = (e) => {
    if (e.target.id === 'initial-query-select') {
      this.setState({
        initial_query_view_name: e.target.value,
        initial_query: e.target[e.target.selectedIndex].dataset.query,
      });
    } else {
      this.setState({
        initial_query: e.target.value,
        initial_query_view_name: null,
      });
    }
  };

  onDatasourceChange = (e) => {
    this.setState({
      datasource_id: e.target.value,
    });
  };

  onAdd = (index, name) => {
    var state = {};
    state[name] = [...this.state[name]];
    state[name].splice(index + 1, 0, {
      id: id(),
    });
    this.setState(state);
  };

  onRemove = (index, name) => {
    var state = {};
    state[name] = [...this.state[name]];
    if ($.isNumeric(state[name][index].id)) {
      state[name][index]._destroy = true;
    } else {
      state[name].splice(index, 1);
    }
    this.setState(state);
  };

  onChange = (index, name, data) => {
    var state = {};
    state[name] = [...this.state[name]];
    state[name][index] = data;
    this.setState(state);
  };

  onChangeOrder = (from, to, name) => {
    var state = {};
    var list = [...this.state[name]];
    var newList = [];
    var fromFound = false;
    list.forEach(function (item) {
      if (item.id == from.id) {
        fromFound = true;
        return;
      }
      if (!fromFound && item.id == to.id) {
        newList.push(from);
      }
      newList.push(item);
      if (fromFound && item.id == to.id) {
        newList.push(from);
      }
    });
    state[name] = newList;
    this.setState(state);
  };

  onScenarioTypeChange = (e) => {
    this.setState({
      scenario_type: e.target.value,
    });
  };

  isFieldsRemovable = (fields) => {
    let count = 0;
    fields.forEach((field) => {
      if (!field._destroy) {
        count++;
      }
    });
    return count > 1;
  };

  renderQueries() {
    return this.state.queries.map((r, index) => {
      if (r._destroy) return null;
      return (
        <div
          key={r.id}
          className="list-group-item list-group-item-action align-items-start"
        >
          <QueryListItem
            removable={this.isFieldsRemovable(this.state.queries)}
            data={r}
            id={`query-${r.id}`}
            field_options={this.state.query_field_options}
            index={index}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChangeOrder={this.onChangeOrder}
            onChange={this.onChange}
            getOptionsByKintoneField={getOptionsByKintoneField}
            lookupFieldMappings={this.state.lookupFieldMappings}
          />
        </div>
      );
    }, this);
  }

  renderUpdateFields() {
    return this.state.update_fields.map((r, index) => {
      if (r._destroy) return null;
      return (
        <div
          key={r.id}
          className="list-group-item list-group-item-action align-items-start"
        >
          <UpdateListItem
            removable={this.isFieldsRemovable(this.state.update_fields)}
            data={r}
            id={`update-${r.id}`}
            field_options={this.state.update_field_options}
            index={index}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChangeOrder={this.onChangeOrder}
            onChange={this.onChange}
            getOptionsByKintoneField={getOptionsByKintoneField}
            getLookupDisplayFields={this.getLookupDisplayFields}
          />
        </div>
      );
    }, this);
  }

  renderSelectFields() {
    return this.state.select_fields.map((r, index) => {
      if (r._destroy) return null;
      return (
        <div
          key={r.id}
          className="list-group-item list-group-item-action align-items-start"
        >
          <SelectListItem
            removable={this.isFieldsRemovable(this.state.select_fields)}
            data={r}
            id={`select-${r.id}`}
            field_options={this.state.select_field_options}
            getFormatByKintoneField={getFormatByKintoneField}
            index={index}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChangeOrder={this.onChangeOrder}
            onChange={this.onChange}
          />
        </div>
      );
    }, this);
  }

  renderSortFields() {
    return this.state.sort_fields.map((r, index) => {
      if (r._destroy) return null;
      return (
        <div
          key={r.id}
          className="list-group-item list-group-item-action align-items-start"
        >
          <SortListItem
            removable={this.isFieldsRemovable(this.state.sort_fields)}
            data={r}
            id={`sort-${r.id}`}
            field_options={this.state.sort_field_options}
            index={index}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChange={this.onChange}
            onChangeOrder={this.onChangeOrder}
          />
        </div>
      );
    }, this);
  }

  renderInputFields() {
    return this.state.input_fields.map((r, index) => {
      if (r._destroy) return null;
      return (
        <div
          key={r.id}
          className="list-group-item list-group-item-action align-items-start"
        >
          <InputListItem
            removable={this.isFieldsRemovable(this.state.input_fields)}
            data={r}
            id={`input-${r.id}`}
            field_options={this.state.input_field_options}
            index={index}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChange={this.onChange}
            onChangeOrder={this.onChangeOrder}
            getOptionsByKintoneField={getOptionsByKintoneField}
            getLookupDisplayFields={this.getLookupDisplayFields}
          />
        </div>
      );
    }, this);
  }

  save = (e) => {
    e.preventDefault();

    var queries = [];
    var selectFields = [];
    var sortFields = [];
    var inputFields = [];
    var updateFields = [];
    var showLink = false;
    var persistentMenu = false;
    var is_update = false;
    var initial_query = null;
    var initial_query_view_name = null;
    var index;
    var ds = this.getDatasource();
    var is_token_auth =
      ds && ds.kintone && !ds.kintone.use_password_authentication;
    var use_scheduled_notification = false;

    if (this.state.scenario_type == 'general_search') {
      if (this.state.is_update === true) {
        const flag = this.state.update_fields.find((field) => {
          return field.field_code;
        });
        if (!flag) {
          this.props.actions.error(
            ['更新するフィールドを選択してください。'],
            {},
          );
          return null;
        }
      }
      index = 0;
      this.state.queries.forEach((item) => {
        if (item.field_code) {
          queries.push({
            id: $.isNumeric(item.id) ? item.id : null,
            field_code: item.field_code,
            field_type: item.field_type,
            field_label: item.field_label,
            options: item.options,
            allow_to_skip: item.allow_to_skip,
            additional_explanation: item.additional_explanation,
            sort_index: index,
            lookup_drill_down_app: item.lookup_drill_down_app,
            lookup_drill_down_field: item.lookup_drill_down_field,
            use_default: item.use_default,
            _destroy: item._destroy,
          });
          index++;
        }
      });
      index = 0;
      this.state.select_fields.forEach((item) => {
        if (item.field_code) {
          selectFields.push({
            id: $.isNumeric(item.id) ? item.id : null,
            field_code: item.field_code,
            field_type: item.field_type,
            field_label: item.field_label,
            sort_index: index,
            format: item.format,
            _destroy: item._destroy,
          });
          index++;
        }
      });
      index = 0;
      this.state.sort_fields.forEach((item) => {
        if (item.field_code) {
          sortFields.push({
            id: $.isNumeric(item.id) ? item.id : null,
            field_code: item.field_code,
            field_type: item.field_type,
            field_label: item.field_label,
            sort_order: item.sort_order,
            sort_index: index,
            _destroy: item._destroy,
          });
          index++;
        }
      });

      index = 0;
      if (this.state.is_update) {
        this.state.update_fields.forEach((item) => {
          if (item.field_code) {
            updateFields.push({
              id: $.isNumeric(item.id) ? item.id : null,
              field_code: item.field_code,
              field_type: item.field_type,
              field_label: item.field_label,
              allow_to_skip: item.allow_to_skip,
              sort_index: index,
              options: item.options,
              lookup_app_id: item.lookup_app_id,
              use_default: item.use_default,
              default_value: item.default_value,
              lookup_related_field: item.lookup_related_field,
              lookup_search_by: item.lookup_search_by,
              lookup_order_by: item.lookup_order_by,
              lookup_display_field: item.lookup_display_field,
              additional_explanation: item.additional_explanation,
              _destroy: item._destroy,
            });
            index++;
          }
        });
      } else {
        this.state.update_fields.forEach((item) => {
          if ($.isNumeric(item.id)) {
            updateFields.push({
              id: item.id,
              _destroy: true,
            });
          }
        });
      }

      showLink = this.state.show_link;
      persistentMenu = this.state.persistent_menu;
      is_update = this.state.is_update;
      use_scheduled_notification = this.state.use_scheduled_notification;
      initial_query =
        this.state.initial_query !== '' ? this.state.initial_query : null;
      initial_query_view_name =
        this.state.initial_query_view_name !== ''
          ? this.state.initial_query_view_name
          : null;
    }

    if (this.state.scenario_type == 'general_register') {
      index = 0;
      this.state.input_fields.forEach((item) => {
        if (item.field_code) {
          inputFields.push({
            id: $.isNumeric(item.id) ? item.id : null,
            field_code: item.field_code,
            field_type: item.field_type,
            field_label: item.field_label,
            options: item.options,
            required: item.required,
            allow_to_skip: item.allow_to_skip,
            use_default: item.use_default,
            default_value: item.default_value,
            sort_index: index,
            lookup_app_id: item.lookup_app_id,
            lookup_related_field: item.lookup_related_field,
            lookup_search_by: item.lookup_search_by,
            lookup_order_by: item.lookup_order_by,
            lookup_display_field: item.lookup_display_field,
            additional_explanation: item.additional_explanation,
            _destroy: item._destroy,
          });
          index++;
        }
      });

      persistentMenu = this.state.persistent_menu;
    }

    var scenario = {
      id: this.props.scenario.id,
      name: this.state.name,
      wakeup_word: this.state.wakeup_word,
      datasource_id: this.state.datasource_id,
      app_url: this.state.app_url,
      api_token: is_token_auth ? this.state.api_token : null,
      scenario_type: this.state.scenario_type,
      show_link: showLink,
      persistent_menu: persistentMenu,
      is_update: is_update,
      use_scheduled_notification: use_scheduled_notification,
      initial_query: initial_query,
      initial_query_view_name: initial_query_view_name,
      queries_attributes: queries,
      select_fields_attributes: selectFields,
      sort_fields_attributes: sortFields,
      input_fields_attributes: inputFields,
      update_fields_attributes: updateFields,
    };
    this.props.actions.saveScenario(scenario, true);
  };

  isValidAppUrl(url) {
    return url.match(/^.*\/k\/(guest\/(\d+)\/)?(\d+)/);
  }

  cancel = () => {
    this.props.actions.clearScenario(true);
  };

  render() {
    var isInvalid = (name) => {
      if (this.props.scenario.errors && this.props.scenario.errors[name]) {
        return 'is-invalid';
      }
      return '';
    };
    var ds = this.getDatasource();

    var words = this.state.wakeup_word;
    words = words ? words.split('\n') : [];
    return (
      <div className={styles.mainBody}>
        <h3>シナリオの設定</h3>
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="scenario-name" className="required">
                名称
              </label>
              <input
                type="text"
                className={`form-control ${isInvalid('name')}`}
                id="scenario-name"
                value={this.state.name || ''}
                onChange={this.onNameChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="scenario-datasource-id" className="required">
                データソース
              </label>
              <select
                className={`form-control ${isInvalid('datasource_id')}`}
                id="scenario-datasource-id"
                value={this.state.datasource_id || ''}
                onChange={this.onDatasourceChange}
              >
                {this.props.datasources.map((o) => {
                  return (
                    <option key={o.id} value={o.id}>
                      {o.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="scenario_type" className="required">
                シナリオの種類
              </label>
              <select
                className={`form-control ${isInvalid('scenario_type')}`}
                id="scenario_type"
                value={this.state.scenario_type || ''}
                onChange={this.onScenarioTypeChange}
              >
                <option value="general_search">汎用検索・更新</option>
                <option value="general_register">汎用登録</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            {ds && ds.kintone && (
              <div className="form-group col-md-6">
                <label htmlFor="scenario-app-url" className="required">
                  アプリのURL
                </label>
                <input
                  type="text"
                  className={`form-control ${isInvalid('app_url')}`}
                  id="scenario-app-url"
                  placeholder={`https://${ds.kintone.subdomain}/k/1`}
                  value={this.state.app_url || ''}
                  onChange={this.onAppUrlChange}
                />
              </div>
            )}
            {ds && ds.kintone && !ds.kintone.use_password_authentication && (
              <div className="form-group col-md-6">
                <label htmlFor="scenario-api-token" className="required">
                  APIトークン
                </label>
                <input
                  type="text"
                  className={`form-control ${isInvalid('api_token')}`}
                  id="scenario-api-token"
                  value={this.state.api_token || ''}
                  onChange={this.onApiTokenChange}
                />
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="scenario-wakeup-word" className="required">
                呼び出し名
              </label>
              <TagEditor tags={words} onChange={this.onWakeupWordChange} />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="phrase">フレーズ</label>
              <div>{this.getPhrase()}</div>
            </div>
          </div>
          {this.state.kintone_loaded ? (
            <div className="mt-3">
              {this.renderScenario()}
              <div className="mt-3 mb-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.save}
                >
                  保存する
                </button>
                <button
                  className="ml-2 btn"
                  type="button"
                  onClick={this.cancel}
                >
                  キャンセル
                </button>
              </div>
            </div>
          ) : (
            <div className="mt-3 mb-3">
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.onNextClick}
              >
                次に、フィールドを設定する
              </button>
              <button className="ml-2 btn" type="button" onClick={this.cancel}>
                キャンセル
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }

  renderScenario = () => {
    switch (this.state.scenario_type) {
      case 'general_search':
        return this.renderGenericSearch();
      case 'general_register':
        return this.renderGenericRegister();
    }
  };

  renderGenericSearch = () => {
    var ds = this.getDatasource();
    return (
      <div>
        <h4>検索するフィールド</h4>
        <div className="list-group mb-5">
          <div className="list-group-item align-items-start">
            <div className="row">
              <div className="col-sm-6">フィールド名</div>
              <div className="col-sm-6" />
            </div>
          </div>
          {this.renderQueries()}
        </div>
        <h4>表示するフィールド</h4>
        <div className="list-group mb-5">
          <div className="list-group-item align-items-start">
            <div className="row">
              <div className="col-sm-6">フィールド名</div>
              <div className="col-sm-6" />
            </div>
          </div>
          {this.renderSelectFields()}
        </div>
        <h4>並び順</h4>
        <div className="list-group mb-5">
          <div className="list-group-item align-items-start">
            <div className="row">
              <div className="col-sm-6">フィールド名</div>
              <div className="col-sm-6" />
            </div>
          </div>
          {this.renderSortFields()}
        </div>
        <h4>表示設定</h4>
        <div className="form-check mb-2">
          <input
            id="scenario-show-link"
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={this.state.show_link || false}
            onChange={this.onShowLinkChange}
          />
          <label className="form-check-label" htmlFor="scenario-show-link">
            リンクを表示
          </label>
        </div>
        <div className="form-check mb-5">
          <input
            id="scenario-persistent-menu"
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={this.state.persistent_menu || false}
            onChange={this.onPersistentMenuChange}
          />
          <label
            className="form-check-label"
            htmlFor="scenario-persistent-menu"
          >
            固定メニューを表示
          </label>
        </div>
        <h4>通知設定</h4>
        <div className="form-check">
          <input
            id="scenario-use-scheduled-notification"
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={this.state.use_scheduled_notification || false}
            onChange={this.onUseScheduledNotificationChange}
          />
          <label
            className="form-check-label"
            htmlFor="scenario-use-scheduled-notification"
          >
            スケジューリング設定をする
          </label>
        </div>
        <div className="mb-5">
          <a
            href="https://joboco.joyzo.co.jp/support/setup/update-notification"
            className=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="help" />
            設定例
          </a>
        </div>
        <h4>初期検索条件</h4>
        <div className="mb-5">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="initial-query-radio"
              value="initial-query-radio-input"
              id="initial-query-radio-input"
              checked={!this.state.condition_initial_query}
              onChange={this.onChangeInitialQueryRadio}
            />
            <label
              className="form-check-label"
              htmlFor="initial-query-radio-input"
            >
              直接入力
            </label>
          </div>

          {ds && ds.kintone && (
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input"
                name="initial-query-radio"
                value="initial-query-radio-select"
                id="initial-query-radio-select"
                checked={this.state.condition_initial_query}
                onChange={this.onChangeInitialQueryRadio}
              />
              <label
                htmlFor="initial-query-radio-select"
                className="form-check-label"
              >
                一覧
              </label>
            </div>
          )}
          {this.state.condition_initial_query ? (
            <SelectItem
              options={this.state.initial_query_list}
              onChange={this.onChangeInitialQueryInput}
              value={this.state.initial_query_view_name}
              id={'initial-query-select'}
              required={false}
              label={'一覧'}
            />
          ) : (
            <div>
              <InputItem
                onChange={this.onChangeInitialQueryInput}
                label={'クエリ'}
                required={false}
                default_value={this.state.initial_query}
                id={'initial-query-input'}
              />
              <a
                href="https://developer.cybozu.io/hc/ja/articles/900001057206"
                className=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="help" />
                クエリ記法の記載例
              </a>
            </div>
          )}
        </div>
        <h4>フィールド更新設定</h4>
        <div className="form-check form-check-inline mb-5">
          <input
            id="scenario-update-field"
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={this.state.is_update || false}
            onChange={this.onUpdateChange}
          />
          <label className="form-check-label" htmlFor="scenario-update-field">
            フィールドを更新
          </label>
        </div>

        {this.state.is_update && (
          <div>
            <h4>更新するフィールド</h4>
            <div className="list-group mb-5">
              <div className="list-group-item align-items-start">
                <div className="row">
                  <div className="col-sm-6">フィールド名</div>
                  <div className="col-sm-6" />
                </div>
              </div>
              {this.renderUpdateFields()}
            </div>
          </div>
        )}
      </div>
    );
  };

  renderGenericRegister = () => {
    return (
      <div>
        <h4>入力するフィールド</h4>
        <div className="list-group mb-5">
          <div className="list-group-item align-items-start">
            <div className="row">
              <div className="col-sm-6">フィールド名</div>
              <div className="col-sm-6" />
            </div>
          </div>
          {this.renderInputFields()}
        </div>
        <h4>表示設定</h4>
        <div className="form-check mb-5">
          <input
            id="scenario-persistent-menu"
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={this.state.persistent_menu || false}
            onChange={this.onPersistentMenuChange}
          />
          <label
            className="form-check-label"
            htmlFor="scenario-persistent-menu"
          >
            固定メニューを表示
          </label>
        </div>
      </div>
    );
  };

  componentDidMount() {
    if (this.state.app_url) {
      if (this.isValidAppUrl(this.state.app_url)) {
        this.fetchAppSettings();
      }
    }
  }
}

export default Edit;
