import { combineReducers } from 'redux';
import * as types from './action_types.js';

function agents(state = [], action = {}) {
  return state;
}

function agent(state = {}, action = {}) {
  return state;
}

function integrations(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_INTEGRATIONS:
      return action.integrations;
    default:
      return state;
  }
}

function integration(state = null, action = {}) {
  switch (action.type) {
    case types.RECEIVE_INTEGRATION:
      return action.integration;
    case types.ERROR:
      if (state && $.isPlainObject(state)) {
        state.errors = action.errorObj;
      }
      return state;
    case types.RECEIVE_INTEGRATIONS:
      return null;
    default:
      return state;
  }
}

function errors(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_INTEGRATIONS:
    case types.RECEIVE_INTEGRATION:
      return [];
    case types.ERROR:
      return action.errors;
    default:
      return state;
  }
}

export default combineReducers({
  agents,
  agent,
  integrations,
  integration,
  errors,
});
