import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/integrations';
import LineWorks from './line_works';
import WebClient from './web_client';

class Edit extends React.Component {
  static propTypes = {
    agent: PropTypes.object.isRequired,
    integration: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.refProvider = React.createRef();
  }

  onProviderChange = (e) => {
    if (e.target.value != this.props.integration.provider) {
      this.props.actions.fetchNewIntegration(e.target.value);
    }
  };

  save = (e) => {
    e.preventDefault();

    var integration = {
      id: this.props.integration.id,
      provider: this.props.integration.provider,
    };

    this.refProvider.current.setAttributes(integration);

    this.props.actions.saveIntegration(integration);
  };

  cancel = () => {
    this.props.actions.clearIntegration(true);
  };

  getProviderName(provider) {
    switch (provider) {
      case 'line_works':
        return 'LINE WORKS';
      case 'web_client':
        return 'Webクライアント（β）';
    }
  }

  render() {
    let Provider;
    switch (this.props.integration.provider) {
      case 'line_works':
        Provider = LineWorks;
        break;
      case 'web_client':
        Provider = WebClient;
        break;
    }
    return (
      <div className={styles.mainBody}>
        <h3>連携サービスの設定</h3>
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="provider" className="required">
                サービスの種類
              </label>
              {this.props.integration.id ? (
                <div>
                  <b>{this.getProviderName(this.props.integration.provider)}</b>
                </div>
              ) : (
                <select
                  className="form-control"
                  id="provider"
                  value={this.props.integration.provider || ''}
                  onChange={this.onProviderChange}
                >
                  <option value="line_works">
                    {this.getProviderName('line_works')}
                  </option>
                  <option value="web_client">
                    {this.getProviderName('web_client')}
                  </option>
                </select>
              )}
            </div>
          </div>

          <Provider
            ref={this.refProvider}
            agent={this.props.agent}
            integration={this.props.integration}
          />
          <div className="mt-3 mb-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.save}
            >
              保存する
            </button>
            <button className="ml-2 btn" type="button" onClick={this.cancel}>
              キャンセル
            </button>
          </div>
        </form>
      </div>
    );
  }
  componentDidMount() {}
}

export default Edit;
