const Modal = {
  confirm: function (
    text,
    okButtonText,
    okCallback,
    cancelButtonText,
    cancelCallback,
  ) {
    if ($('.modal-confirm').length > 0) return;

    var $node = $('<div />', { class: 'modal fade modal-confirm' })
      .appendTo('body')
      .hide();
    var $content = $('<div class="modal-content"></div>');
    var $body = $('<div class="modal-body"></div>');
    $body.append($('<p></p>').text(text));
    var $footer = $('<div class="modal-footer"></div>');
    var $cancel = $(
      '<button type="button" class="btn btn-default" data-dismiss="modal"></button>',
    );
    $cancel.text(cancelButtonText || 'キャンセル');
    var $ok = $('<button type="button" class="btn btn-primary"></button>');
    $footer.append($cancel).append($ok);
    $content.append($body).append($footer);
    $node.append(
      $('<div class="modal-dialog modal-dialog-centered">').append($content),
    );

    var close = function () {
      $node.modal('hide');
      $ok.off();
      $cancel.off();
      //$node.remove();
    };
    $ok.text(okButtonText || 'OK');
    $cancel.click(function (e) {
      close();
      if (cancelCallback) {
        cancelCallback.call(this);
      }
    });
    $node.on('shown.bs.modal', function () {
      $ok.focus();
    });
    $node.on('hidden.bs.modal', function () {
      $node.remove();
    });
    $ok.click(function (e) {
      close();
      if (okCallback) {
        okCallback.call(this);
      }
    });
    $node.modal();
  },
  toast: function (style, msg, html) {
    if (style == 'alert') style = 'danger';
    if (style == 'notice') style = 'success';

    var $alert = $(
      '<div class="alert alert-dismissible fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>',
    );
    $alert.addClass('toast-' + style);
    if (html) {
      $alert.append($('<div></div>').html(msg));
    } else {
      $alert.append($('<span></span>').text(msg));
    }
    var $container = $('#toast-container');
    if ($container.length === 0) {
      $container = $('<div id="toast-container"></div>');
      $container.appendTo($('body'));
    }
    $container.prepend($alert);
    setTimeout(function () {
      $alert.fadeOut(400, function () {
        $alert.remove();
      });
    }, 3000);
  },
  modal: function ($node, fixed) {
    var opts = {};
    if (fixed) {
      opts = { backdrop: 'static', keyboard: false };
    }
    $node.modal(opts);
  },
};

export default Modal;
