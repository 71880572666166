import React, { forwardRef, useMemo } from 'react';
import NumberInput from 'components/base/number_input';
import DateTimeInput from 'components/base/datetime_input';
import styles from 'src/webhook_notifications';

const textOperators = [
  'eq',
  'ne',
  'contain',
  'not_contain',
  'start_with',
  'end_with',
];
const numberOperators = ['eq', 'ne', 'le', 'lt', 'ge', 'gt'];
const multiSelectOperators = ['include', 'not_include'];
const fieldOperators = {
  SINGLE_LINE_TEXT: textOperators,
  MULTI_LINE_TEXT: textOperators,
  RICH_TEXT: textOperators,
  RADIO_BUTTON: multiSelectOperators,
  DROP_DOWN: multiSelectOperators,
  STATUS: multiSelectOperators,
  LINK: textOperators,
  NUMBER: numberOperators,
  CHECK_BOX: multiSelectOperators,
  MULTI_SELECT: multiSelectOperators,
  CREATOR: multiSelectOperators,
  MODIFIER: multiSelectOperators,
  USER_SELECT: multiSelectOperators,
  STATUS_ASSIGNEE: multiSelectOperators,
  ORGANIZATION_SELECT: multiSelectOperators,
  GROUP_SELECT: multiSelectOperators,
  DATE: numberOperators,
  TIME: numberOperators,
  DATETIME: numberOperators,
  COMMENT_MENTION: multiSelectOperators,
};

const operatorLabels = {
  eq: '等しい',
  ne: '等しくない',
  le: '以下',
  lt: 'より小さい',
  ge: '以上',
  gt: 'より大きい',
  contain: '次の文字列を含む',
  not_contain: '次の文字列を含まない',
  include: '次のいずれかを含む',
  not_include: '次のいずれも含まない',
  start_with: '次の文字列で始まる',
  end_with: '次の文字列で終わる',
};

function ConditionValue({ field, condition, onChange }) {
  const value = condition.value;
  const values = useMemo(() => {
    try {
      return JSON.parse(
        value ||
          (field.options
            ? '[]'
            : field.type == 'COMMENT_MENTION'
              ? '["USER:"]'
              : '[""]'),
      );
    } catch (e) {}
    return [];
  }, [field, value]);
  const required = useMemo(() => {
    return ['le', 'lt', 'ge', 'gt'].includes(condition.operator);
  }, [condition]);

  if (!field) {
    return (
      <input
        className="form-control"
        type="text"
        value={''}
        onChange={(e) => {}}
      />
    );
  } else if (field.type == 'NUMBER') {
    return (
      <NumberInput
        className="form-control text-right"
        value={value || ''}
        onChange={onChange}
      />
    );
  } else if (field.type == 'DATE') {
    return (
      <div className="">
        <input
          className="form-control"
          type="date"
          required={required}
          value={value || ''}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </div>
    );
  } else if (field.type == 'TIME') {
    return (
      <div className="">
        <input
          className="form-control"
          type="time"
          required={required}
          value={value || ''}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </div>
    );
  } else if (field.type == 'DATETIME') {
    return (
      <DateTimeInput
        className="form-control"
        required={required}
        value={value || ''}
        onChange={onChange}
      />
    );
  } else if (
    [
      'CREATOR',
      'MODIFIER',
      'USER_SELECT',
      'STATUS_ASSIGNEE',
      'ORGANIZATION_SELECT',
      'GROUP_SELECT',
    ].includes(field.type)
  ) {
    return (
      <div>
        {values.map((v, index) => (
          <div key={index} className={styles.conditionValue}>
            <input
              className="form-control"
              type="text"
              value={v || ''}
              onChange={(e) => {
                const newValues = [...values];
                newValues[index] = e.target.value;
                onChange(JSON.stringify(newValues));
              }}
            />
            {values.length > 1 && (
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  const newValues = [...values];
                  newValues.splice(index, 1);
                  onChange(JSON.stringify(newValues));
                }}
              >
                <i className="material-icons mr-1" style={{ fontSize: '16px' }}>
                  remove_circle
                </i>
              </a>
            )}
          </div>
        ))}
        <div className="mt-2">
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              onChange(JSON.stringify([...values, '']));
            }}
            className={styles.addValue}
          >
            <i className="material-icons mr-1">add_circle</i>
            <span>値を追加する</span>
          </a>
        </div>
      </div>
    );
  } else if (['COMMENT_MENTION'].includes(field.type)) {
    return (
      <div>
        {values.map((v, index) => {
          const [type, code] = v.split(':');
          return (
            <div key={index} className={styles.conditionValue}>
              <select
                className="form-control"
                value={type}
                onChange={(e) => {
                  const newValues = [...values];
                  newValues[index] = `${e.target.value}:${code}`;
                  onChange(JSON.stringify(newValues));
                }}
              >
                <option value="USER">ユーザー</option>
                <option value="ORGANIZATION">組織</option>
                <option value="GROUP">グループ</option>
              </select>
              <input
                className="form-control"
                type="text"
                value={code || ''}
                onChange={(e) => {
                  const newValues = [...values];
                  newValues[index] = `${type}:${e.target.value}`;
                  onChange(JSON.stringify(newValues));
                }}
              />
              {values.length > 1 && (
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    const newValues = [...values];
                    newValues.splice(index, 1);
                    onChange(JSON.stringify(newValues));
                  }}
                >
                  <i
                    className="material-icons mr-1"
                    style={{ fontSize: '16px' }}
                  >
                    remove_circle
                  </i>
                </a>
              )}
            </div>
          );
        })}
        <div className="mt-2">
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              onChange(JSON.stringify([...values, 'USER:']));
            }}
            className={styles.addValue}
          >
            <i className="material-icons mr-1">add_circle</i>
            <span>値を追加する</span>
          </a>
        </div>
      </div>
    );
  } else if (['include', 'not_include'].includes(condition.operator)) {
    const options = field.options || [];
    return (
      <div className={styles.multiSelectWrapper}>
        {field.type !== 'STATUS' && (
          <div>
            <label
              className={`checkbox-wrapper ${styles.checkboxWrapper} ${styles.textNowrapEllipsis}`}
            >
              <input
                className={styles.flexNone}
                type="checkbox"
                checked={values.includes('') || false}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChange(JSON.stringify([...values, '']));
                  } else {
                    onChange(JSON.stringify(values.filter((v) => v != '')));
                  }
                }}
              />
              --
            </label>
          </div>
        )}
        {options.map((opt) => (
          <div key={opt}>
            <label
              className={`checkbox-wrapper ${styles.checkboxWrapper} ${styles.textNowrapEllipsis}`}
            >
              <input
                className={styles.flexNone}
                type="checkbox"
                checked={values.includes(opt) || false}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChange(JSON.stringify([...values, opt]));
                  } else {
                    onChange(JSON.stringify(values.filter((v) => v != opt)));
                  }
                }}
              />
              {opt}
            </label>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <input
        className="form-control"
        type="text"
        value={value || ''}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    );
  }
}

export default function Condition({
  condition,
  conditionFields,
  onChange,
  onRemove,
}) {
  const field = useMemo(() => {
    return conditionFields.find((field) => {
      return field.code === condition.source;
    });
  }, [condition.source, conditionFields]);
  const operators = useMemo(() => {
    return (field?.type ? fieldOperators[field?.type] : undefined) || [];
  }, [field]);

  const onConditionSourceChange = (e) => {
    const source = e.target.value;
    const field = conditionFields.find((field) => {
      return field.code === source;
    });
    if (!field) {
      return;
    }
    const operator = fieldOperators[field?.type][0];
    onChange({ ...condition, source: e.target.value, operator, value: '' });
  };

  const onConditionOperatorChange = (e) => {
    onChange({ ...condition, operator: e.target.value });
  };

  const onConditionValueChange = (value) => {
    onChange({ ...condition, value });
  };
  return (
    <div className="list-group-item list-group-item-action">
      <div className="row align-items-start">
        <div className="col-sm-4">
          <select
            className="form-control"
            value={condition.source}
            onChange={onConditionSourceChange}
          >
            <option value=""></option>
            {conditionFields.map((field) => {
              return (
                <option key={field.code} value={field.code}>
                  {field.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-sm-3">
          <select
            className="form-control"
            value={condition.operator}
            onChange={onConditionOperatorChange}
          >
            {operators.map((operator) => {
              return (
                <option key={operator} value={operator}>
                  {operatorLabels[operator]}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-sm-4">
          <ConditionValue
            field={field}
            condition={condition}
            onChange={onConditionValueChange}
          />
        </div>
        <div className="col-sm-1 align-self-center ">
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              onRemove(e);
            }}
          >
            <i className="material-icons mr-1">remove_circle</i>
          </a>
        </div>
      </div>
    </div>
  );
}
