import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from 'src/datasources';
import Errors from 'components/base/errors';
import * as actions from './actions';
import Edit from './edit';

class Container extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <Errors errors={this.props.errors} onError={this.props.actions.error} />
        {this.props.datasource ? (
          <Edit
            datasource={this.props.datasource}
            actions={this.props.actions}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    datasource: state.datasource,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Container);
