import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base/icon';

class DatePicker extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    name: null,
    id: null,
    value: '',
    onChange: function () {},
  };

  render() {
    return (
      <div className="input-group date">
        <input
          ref={(c) => {
            this.refInput = c;
          }}
          type="text"
          className="form-control"
          defaultValue={this.props.value}
          name={this.props.name}
          id={this.props.id}
          onChange={this.onChange}
        />
        <div className="input-group-append" onClick={this.onIconClick}>
          <span className="input-group-text">
            <Icon name="date_range" />
          </span>
        </div>
      </div>
    );
  }

  onChange = (e) => {
    var val = e.target.value;
    if (val.length === 0) {
      this.props.onChange(null);
    }
  };

  getPicker = () => {
    return $(this.refInput);
  };

  onIconClick = (e) => {
    var $this = this.getPicker();
    $this.datepicker('show');
  };

  componentDidMount() {
    var self = this;
    var $this = this.getPicker();
    var picker = $this.datepicker({
      autoclose: true,
      language: app.language,
      format: 'yyyy-mm-dd',
    });
    picker.on('changeDate', function (e) {
      var val = $(e.target).val();
      self.props.onChange(val);
    });
  }

  componentWillUnmount() {
    var $this = this.getPicker();
    $this.datepicker('destroy');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var val = this.props.value;

    var $node = this.getPicker();
    if ($node.val() !== val) {
      $node.val(val);
      if (val) {
        $node.datepicker('setDate', val);
      } else {
        $node.datepicker('clearDate');
      }
    }
  }
}

export default DatePicker;
