import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UaUtil from 'utils/ua';
import Sidebar from 'components/shared/agents/sidebar';
import styles from 'src/webhook_notifications';
import Errors from 'components/base/errors';
import Alerts from 'components/base/alerts';
import * as actions from './actions';
import Index from './index';
import Edit from './edit';

class Container extends React.Component {
  render() {
    return (
      <div className={`d-flex ${styles.container}`}>
        <Sidebar
          agents={this.props.agents}
          agent={this.props.agent}
          active="webhook_notifications"
        />
        <div className={styles.main}>
          <Errors
            errors={this.props.errors}
            onError={this.props.actions.error}
          />
          <Alerts
            alerts={this.props.alerts}
            onAlert={this.props.actions.alert}
          />
          {this.props.webhookNotification ? (
            <Edit
              agent={this.props.agent}
              datasources={this.props.datasources}
              webhookNotification={this.props.webhookNotification}
              actions={this.props.actions}
            />
          ) : (
            <Index
              agent={this.props.agent}
              webhookNotifications={this.props.webhookNotifications}
              actions={this.props.actions}
            />
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (window.history && window.history.pushState) {
      $(window).on(
        'popstate',
        function (e) {
          var state = e.originalEvent.state;
          this.props.actions.setPoppedState(state);
        }.bind(this),
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    agents: state.agents,
    datasources: state.datasources,
    agent: state.agent,
    webhookNotifications: state.webhookNotifications,
    webhookNotification: state.webhookNotification,
    alerts: state.alerts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Container);
