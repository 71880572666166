import { combineReducers } from 'redux';
import * as types from './action_types.js';

function agents(state = [], action = {}) {
  return state;
}

function datasources(state = [], action = {}) {
  return state;
}

function agent(state = {}, action = {}) {
  return state;
}

function webhookNotifications(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_WEBHOOK_NOTIFICATIONS:
      return action.webhookNotifications;
    default:
      return state;
  }
}

function webhookNotification(state = null, action = {}) {
  switch (action.type) {
    case types.RECEIVE_WEBHOOK_NOTIFICATION:
      return action.webhookNotification;
    case types.ERROR:
      if (state && $.isPlainObject(state)) {
        state.errors = action.errorObj;
      }
      return state;
    case types.RECEIVE_WEBHOOK_NOTIFICATIONS:
      return null;
    default:
      return state;
  }
}

function errors(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_WEBHOOK_NOTIFICATIONS:
    case types.RECEIVE_WEBHOOK_NOTIFICATION:
      return [];
    case types.ERROR:
      return action.errors;
    default:
      return state;
  }
}

function alerts(state = [], action = {}) {
  switch (action.type) {
    case types.RECEIVE_WEBHOOK_NOTIFICATIONS:
    case types.RECEIVE_WEBHOOK_NOTIFICATION:
      return [];
    case types.ALERT:
      return action.alerts;
    default:
      return state;
  }
}

export default combineReducers({
  agents,
  datasources,
  agent,
  webhookNotifications,
  webhookNotification,
  errors,
  alerts,
});
