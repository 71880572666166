const getOptionsByKintoneField = (opt) => {
  const res = Array(Object.keys(opt.options).length);
  for (const label in opt.options) {
    const option = opt.options[label];
    res[option.index] = option.label;
  }
  return res;
};

export { getOptionsByKintoneField };
