const UrlUtil = {
  _parseParameters: function (key, params) {
    var ret;
    var isArray = false;
    if (key.match(/.+\[\]$/)) {
      ret = [];
      isArray = true;
    }
    for (var i = 0; i < params.length; i++) {
      var pair = params[i].split('=');
      if (pair.length > 1) {
        var k = pair[0];
        var v = pair[1];
        if (k == key) {
          if (isArray) {
            ret.push(v);
          } else {
            return v;
          }
        }
      }
    }
    return ret;
  },
  parseLocationHash: function (key) {
    return UrlUtil._parseParameters(
      key,
      location.hash.replace('#', '').split('&'),
    );
  },
  parseQueryString: function (key) {
    return UrlUtil._parseParameters(key, location.search.slice(1).split('&'));
  },
  removeLocationHash: function (key) {
    if (location.hash == '' || location.hash == '#') return;
    var params = location.hash.replace('#', '').split('&');
    var newHash = [];
    $.each(params, function (i, param) {
      var pair = param.split('=');
      if (pair.length === 1) return;
      var k = pair[0];
      var v = pair[1];
      if (k != key) {
        newHash.push(k + '=' + v);
      }
    });
    location.hash = newHash.join('&');
  },
  getIdFromHash: function (key) {
    var id = UrlUtil.parseLocationHash(key);
    try {
      if (id) {
        id = parseInt(id, 10);
      }
    } catch (e) {
      id = undefined;
    }
    return id;
  },
  get: function (path, ext) {
    var API_PATH = '';
    var buff = [API_PATH];
    if (path.indexOf('http') !== 0 && path.charAt(0) !== '/') {
      buff.push('/');
    }
    buff.push(path);
    if (typeof ext != 'undefined') {
      if (ext.charAt(0) != '.') {
        ext = '.' + ext;
      }
      buff.push(ext);
    }
    return buff.join('');
  },
  api: function (path) {
    return '/api' + UrlUtil.json(path);
  },
  json: function (path) {
    return UrlUtil.get(path, '.json');
  },
  absUrlFor: function (path) {
    if (path.charAt(0) == '/') {
      path = path.substr(1);
    }
    if (app.useSubdomain) {
      return app.serviceUrl + path;
    } else {
      return [location.protocol, '//', location.host, '/', path].join('');
    }
  },
  buildUrl: function (path, params) {
    var q = [];
    for (var key in params) {
      var val = encodeURIComponent(params[key]);
      q.push(`${key}=${val}`);
    }
    return path + '?' + q.join('&');
  },
  isValidUrl: function (url) {
    if (!url) return false;
    return url.match(/^https?:\/\//);
  },
};

export default UrlUtil;
