import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

const ReactRenderer = {
  render: (reducer, App, target, options, callback) => {
    if (target) {
      if (typeof target === 'string') {
        target = document.getElementById(target);
      }
    } else {
      target = document.getElementById('react-container');
    }
    if (!target) {
      throw new Error('Container is not found.');
    }
    const store = createStore(reducer, options, applyMiddleware(thunk));

    let component;

    ReactDOM.render(
      <Provider store={store}>
        <App
          ref={(c) => {
            component = c;
          }}
        />
      </Provider>,
      target,
      () => {
        if (callback) {
          var container = component.getWrappedInstance();
          if (container.ref && container.ref.current) {
            // for DragDropContext
            container = container.ref.current;
          }
          callback(container, store);
        }
      },
    );

    return store;
  },
};

export default ReactRenderer;
