import UrlUtil from 'utils/url';
import reducer from 'components/admins/users/reducers';
import App from 'components/admins/users/container';
import renderer from 'controllers/react_renderer';
import { fetchUsers } from 'components/admins/users/actions';

window.app.pages.AdminsUsersIndex = function () {
  var filter = UrlUtil.parseQueryString('filter');
  var page = UrlUtil.parseQueryString('page');

  var store = renderer.render(reducer, App, 'react-container', {
    filter: filter || 'available',
    navi: { limit: 50, page: page || 1, count: 0 },
  });
  store.dispatch(fetchUsers(1));
};
