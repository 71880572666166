import Xhr from 'utils/xhr.js';
import Modal from 'utils/modal';
import { id } from './edit.js';
import * as types from './action_types.js';

export const receiveWebhookNotification = (webhookNotification) => {
  return {
    type: types.RECEIVE_WEBHOOK_NOTIFICATION,
    webhookNotification,
  };
};

export const receiveWebhookNotifications = (webhookNotifications) => {
  return {
    type: types.RECEIVE_WEBHOOK_NOTIFICATIONS,
    webhookNotifications,
  };
};

export const setPoppedState = (popedState) => {
  return (dispatch, getState) => {
    if (popedState == 'new') {
      dispatch(newWebhookNotification(false));
    } else if (popedState) {
      dispatch(setWebhookNotification(popedState, false));
    } else {
      dispatch(fetchWebhookNotifications(false));
    }
  };
};

const setHistory = (state, webhookNotificationId) => {
  if (window.history && window.history.pushState) {
    var url = `/agents/${state.agent.id}/webhook_notifications`;
    if (webhookNotificationId == 'new') {
      url = url + '/new';
    } else if (webhookNotificationId) {
      url = url + `/${webhookNotificationId}/edit`;
    }
    window.history.pushState(webhookNotificationId, '', url);
  }
};

export const newWebhookNotification = (pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    if (pushState) {
      setHistory(state, 'new');
    }
    dispatch(
      receiveWebhookNotification({
        id: 0,
        name: '',
        app_url: '',
        api_token: '',
        logical_statement: 0,
        message_body: '',
        notification_types: 7,
        conditions: [],
        recipients: [],
      }),
    );
  };
};

export const duplicateWebhookNotification = (
  webhookNotificationId,
  pushState,
) => {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/webhook_notifications/${webhookNotificationId}`,
      {},
      (data) => {
        if (pushState) {
          setHistory(state, 'new');
        }
        const wn = data.webhook_notification;
        wn.id = 0;
        wn.name = `${wn.name}のコピー`;
        const childFields = ['conditions', 'recipients'];
        childFields.forEach((field) => {
          wn[field] = wn[field].map((f) => {
            f.id = null;
            return f;
          });
        });
        dispatch(receiveWebhookNotification(wn));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
};

export const setWebhookNotification = (webhookNotificationId, pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/webhook_notifications/${webhookNotificationId}`,
      {},
      (data) => {
        if (pushState) {
          setHistory(state, webhookNotificationId);
        }
        dispatch(receiveWebhookNotification(data.webhook_notification));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
};

export const fetchWebhookNotifications = (pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    Xhr.execute(
      'GET',
      `agents/${state.agent.id}/webhook_notifications`,
      {},
      (data) => {
        if (pushState) {
          setHistory(state, null);
        }
        dispatch(receiveWebhookNotifications(data.webhook_notifications));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
};

export const saveWebhookNotification = (webhookNotification) => {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = `agents/${state.agent.id}/webhook_notifications`;
    if (webhookNotification.id) {
      method = 'PATCH';
      url += `/${webhookNotification.id}`;
    } else {
      method = 'POST';
    }
    Xhr.execute(
      method,
      url,
      webhookNotification,
      (data) => {
        Modal.toast(
          'alert',
          '設定が保存されました。チャットボットへの反映を行ってください。',
        );
        dispatch(fetchWebhookNotifications(true));
      },
      (xhr, status, err) => {
        dispatch(
          error(Xhr.getErrors(xhr, 'webhook_notification'), xhr.responseJSON),
        );
      },
    );
  };
};

export const deleteWebhookNotification = (webhookNotification) => {
  return (dispatch, getState) => {
    var state = getState();
    var method;
    var url = `agents/${state.agent.id}/webhook_notifications/${webhookNotification.id}`;
    method = 'DELETE';
    Xhr.execute(
      method,
      url,
      {},
      (data) => {
        dispatch(fetchWebhookNotifications(false));
      },
      (xhr, status, err) => {
        dispatch(
          error(Xhr.getErrors(xhr, 'webhook_notification'), xhr.responseJSON),
        );
      },
    );
  };
};

export const clearWebhookNotification = (pushState) => {
  return (dispatch, getState) => {
    var state = getState();
    if (pushState) {
      setHistory(state, null);
    }
    dispatch(receiveWebhookNotification(null));
  };
};

export const error = (errors, errorObj) => {
  return {
    type: types.ERROR,
    errors,
    errorObj,
  };
};

export const alert = (alerts, alertObj) => {
  return {
    type: types.ALERT,
    alerts,
    alertObj,
  };
};
