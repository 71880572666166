import React from 'react';

const Option = ({ value, label, custom }) => {
  return (
    <option {...custom} value={value}>
      {label}
    </option>
  );
};

const SelectItem = ({ options, onChange, value, id, required, label }) => {
  return (
    <div className="form-row mt-3">
      <div className="form-group col-md-6">
        <label
          htmlFor="initial-query-input"
          className={required ? 'required' : ''}
        >
          {label}
        </label>
        <select
          className="form-control"
          value={value || ''}
          onChange={onChange}
          id={id}
        >
          <option key="#blank" value="" />
          {options.map((o, index) => {
            return (
              <Option
                key={index}
                label={o.name}
                value={o.name}
                custom={o.custom}
              />
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SelectItem;
