import React from 'react';

const InputItem = ({ label, onChange, required, default_value, id }) => {
  return (
    <div className="form-row mt-3">
      <div className="form-group col-md-6">
        <label
          htmlFor="initial-query-input"
          className={required ? 'required' : ''}
        >
          {label}
        </label>
        <input
          type="text"
          className={`form-control`}
          id={id}
          onChange={onChange}
          defaultValue={default_value}
        />
      </div>
    </div>
  );
};

export default InputItem;
