import Icon from 'components/base/icon';
import App from 'components/deploy/container';
import reducer from 'components/deploy/reducers';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import styles from 'src/sidebar';
import Modal from 'utils/modal';
import Xhr from 'utils/xhr.js';

class MenuItem extends React.Component {
  render() {
    return (
      <a
        className="dropdown-item"
        href={`/agents/${this.props.agent.id}/scenarios`}
      >
        {this.props.agent.name}
      </a>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.refDeploy = React.createRef();
  }

  agents() {
    var menuitems = this.props.agents.map((agent) => {
      return <MenuItem key={agent.id} agent={agent} />;
    });
    menuitems.push(<div key={'divider'} className="dropdown-divider" />);

    if (this.props.agent) {
      menuitems.push(
        <a
          key={'edit'}
          className="dropdown-item"
          href={`/agents/${this.props.agent.id}/edit`}
        >
          <i className="material-icons mr-1">edit</i>
          {'チャットボットの編集'}
        </a>,
      );
      if (this.props.agents.length > 1) {
        menuitems.push(
          <a
            key={'delete'}
            className="dropdown-item"
            href="#!"
            onClick={this.onDeleteAgentClick}
          >
            <i className="material-icons mr-1">delete</i>
            {'チャットボットの削除'}
          </a>,
        );
      }
    }
    menuitems.push(
      <a key={'new'} className="dropdown-item" href="/agents/new">
        <i className="material-icons mr-1">add_box</i>
        {'チャットボットの新規作成'}
      </a>,
    );
    return menuitems;
  }
  isActive(name) {
    if (name == this.props.active) {
      return styles.active;
    }
    return null;
  }
  onDeleteAgentClick = (e) => {
    e.preventDefault();
    Modal.confirm(
      'チャットボットを削除します。この操作は元に戻せません。よろしいですか？',
      null,
      () => {
        Xhr.execute(
          'DELETE',
          `agents/${this.props.agent.id}`,
          {},
          (data) => {
            window.location.href = '/agents';
          },
          (xhr, status, err) => {},
        );
      },
    );
  };

  render() {
    var agent = this.props.agent;
    return (
      <div id="sidebar-wrapper" className={styles.sidebar}>
        <div className={`dropdown ${styles.dropdown}`}>
          <label>設定中のチャットボット</label>
          <a
            className={`${styles.customSelect} custom-select text-left`}
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {agent ? agent.name : ''}
          </a>
          <div
            className={`dropdown-menu ${styles.dropdownMenu}`}
            aria-labelledby="dropdownMenuButton"
          >
            {this.agents()}
          </div>
        </div>
        <ul className={styles.sidebarNav}>
          <li>
            <a
              href={agent ? `/agents/${agent.id}/scenarios` : '#!'}
              className={this.isActive('scenarios')}
            >
              <Icon name="forum" />
              シナリオ
            </a>
          </li>
          <li>
            <a
              href={agent ? `/agents/${agent.id}/webhook_notifications` : '#!'}
              className={this.isActive('webhook_notifications')}
            >
              <Icon name="notifications" />
              更新通知
            </a>
          </li>
          <li>
            <a
              href={agent ? `/agents/${agent.id}/integrations` : '#!'}
              className={this.isActive('integrations')}
            >
              <Icon name="settings" />
              連携サービス
            </a>
          </li>
        </ul>
        <ul className={`${styles.sidebarNav} mt-3`}>
          <li className={styles.deploy}>
            <div ref={this.refDeploy} />
          </li>
        </ul>
      </div>
    );
  }
  componentDidMount() {
    var container = this.refDeploy.current;

    const store = createStore(
      reducer,
      {
        agent: this.props.agent,
      },
      applyMiddleware(thunk),
    );

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      container,
    );
  }
}

Sidebar.propTypes = {
  agents: PropTypes.array.isRequired,
  active: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
  agent: null,
};

export default Sidebar;
