import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base/icon';
import styles from 'src/base';

class SelectedTag extends React.Component {
  static propTypes = {
    tag: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className={styles.selectedTag}>
        <span>
          {this.props.tag}
          <a href="#" onClick={this.onRemove}>
            <Icon name="close" />
          </a>
        </span>
      </div>
    );
  }

  onRemove = (e) => {
    e.preventDefault();
    this.props.onRemove(this.props.tag);
  };
}

class TagEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    tags: PropTypes.array.isRequired,
  };

  static defaultProps = {
    onChange: null,
  };

  constructor(props) {
    super(props);
    var state = {
      tags: [].concat(props.tags),
      input: '',
    };

    this.state = state;
  }

  getTags = () => {
    return $.extend([], this.state.tags);
  };

  render() {
    return (
      <div className="form-control">
        <div className={styles.selectedTags} onClick={this.focusInput}>
          {this.state.tags.map(function (tag) {
            return (
              <SelectedTag
                key={tag}
                tag={tag}
                onRemove={this.onSelectedTagRemoved}
              />
            );
          }, this)}
          <input
            ref={(c) => {
              this.refInput = c;
            }}
            type="text"
            onKeyDown={this.onInputKeyDown}
            value={this.state.input || ''}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
          />
        </div>
      </div>
    );
  }

  addTag = (tag) => {
    tag = tag.replace(/[#?+/\s]/g, '');

    var filtered = this.state.tags.filter((t) => {
      return t == tag;
    });
    if (!tag || filtered.length > 0) {
      this.setState({
        input: '',
      });
      return;
    }

    var tags = [].concat(this.state.tags);
    tags.push(tag);
    this.setState({
      tags: tags,
      input: '',
    });
  };

  removeTag = (tag) => {
    for (var i = 0; i < this.state.tags.length; i++) {
      if (tag == this.state.tags[i]) {
        var tags = [].concat(this.state.tags);
        tags.splice(i, 1);
        this.setState({
          tags: tags,
          input: '',
        });
      }
    }
  };

  onSelectedTagRemoved = (label) => {
    this.removeTag(label);
  };

  onInputKeyDown = (e) => {
    var code = e.keyCode;
    switch (code) {
      case 13: // Enter
      case 32: // Space
      case 9: // Tab
        this.addTag(this.state.input);
        e.preventDefault();
        break;
      case 8:
        if (this.state.tags.length > 0 && !this.state.input) {
          this.removeTag(this.state.tags[this.state.tags.length - 1]);
        }
        break;
      default:
        break;
    }
  };

  onInputChange = (e) => {
    this.setState({ input: e.target.value });
  };

  onInputBlur = (e) => {
    if (this.state.input) {
      this.addTag(this.state.input);
    }
  };

  focusInput = (e) => {
    this.refInput.focus();
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state && this.props.onChange) {
      this.props.onChange(this.state.tags);
    }
  }
}

export default TagEditor;
