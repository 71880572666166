import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from 'src/admins';
import Errors from 'components/base/errors';
import * as actions from './actions';
import Edit from './edit';
import Index from './index';

class Container extends React.Component {
  render() {
    return (
      <div className={`${styles.container}`}>
        <Errors errors={this.props.errors} onError={this.props.actions.error} />
        {this.props.user ? (
          <Edit user={this.props.user} actions={this.props.actions} />
        ) : (
          <Index
            users={this.props.users}
            filter={this.props.filter}
            navi={this.props.navi}
            actions={this.props.actions}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    user: state.user,
    filter: state.filter,
    navi: state.navi,
    errors: state.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Container);
