import Xhr from 'utils/xhr.js';
import * as types from './action_types.js';

const buildUrl = (id) => {
  var url = 'admins/users';
  if (id) {
    url += `/${id}`;
  }
  return url;
};

export const receiveUsers = (users, navi) => {
  return {
    type: types.RECEIVE_USERS,
    users,
    navi,
  };
};

export const receiveUser = (user) => {
  return {
    type: types.RECEIVE_USER,
    user,
  };
};

export const newUser = () => {
  return receiveUser({
    id: 0,
    email: '',
    name: '',
    company: '',
    service_plan: 'trial',
  });
};

export const fetchUsers = (newPage) => {
  return (dispatch, getState) => {
    var state = getState();
    newPage = newPage || state.navi.page;
    Xhr.execute(
      'GET',
      buildUrl(),
      {
        limit: state.navi.limit,
        offset: (newPage - 1) * state.navi.limit,
        filter: state.filter,
        searchText: state.searchText,
      },
      (data) => {
        var navi = {
          limit: state.navi.limit,
          page: newPage,
          count: data.count,
        };
        dispatch(receiveUsers(data.users, navi));
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr)));
      },
    );
  };
};

export const setFilter = (filter) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_FILTER,
      filter,
    });
    return dispatch(fetchUsers(1));
  };
};

export const saveUser = (user) => {
  return (dispatch, getState) => {
    var method;
    var url;
    if (user.id) {
      method = 'PATCH';
      url = buildUrl(user.id);
    } else {
      method = 'POST';
      url = buildUrl();
    }
    Xhr.execute(
      method,
      url,
      { user },
      (data) => {
        dispatch(fetchUsers());
      },
      (xhr, status, err) => {
        dispatch(error(Xhr.getErrors(xhr, 'user'), xhr.responseJSON));
      },
    );
  };
};

export const setSearchText = (searchText) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_SEARCH_TEXT,
      searchText,
    });
    return dispatch(fetchUsers(1));
  };
};

export const setPage = (page) => {
  return (dispatch, getState) => {
    var state = getState();
    if (page < 1) page = 1;
    var pageMax = Math.ceil(state.navi.count / state.navi.limit);
    if (page > pageMax) page = pageMax;
    if (state.navi.page != page) {
      return dispatch(fetchUsers(page));
    }
  };
};

export const error = (errors, errorObj) => {
  return {
    type: types.ERROR,
    errors,
    errorObj,
  };
};
