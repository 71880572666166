import reducer from 'components/integrations/reducers';
import App from 'components/integrations/container';
import renderer from 'controllers/react_renderer';
import { newIntegration } from 'components/integrations/actions';

window.app.pages.IntegrationsNew = function () {
  var store = renderer.render(reducer, App, 'react-container', {
    agents: app.data.settings.agents,
    agent: app.data.settings.agent,
    integrations: app.data.integrations,
  });
  store.dispatch(newIntegration(false));
};
