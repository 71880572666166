import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base/icon';

class WebClient extends React.Component {
  static propTypes = {
    agent: PropTypes.object.isRequired,
    integration: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };
  }

  setAttributes = (integration) => {
    const webClient = this.props.integration.web_client;
    integration.web_client_integration_attributes = {
      id: webClient.id,
      api_key: webClient.api_key,
      signature: webClient.signature,
    };
  };

  htmlCode = () => {
    const webClient = this.props.integration.web_client;
    let suffix = '';
    if (app.botApiUrl.indexOf('https://api-stage.joboco.io') >= 0) {
      suffix = '_stg';
    }
    const html = `\
(function () {\
  var eventHandler = function () {\n\
    const script = document.createElement("script");\n\
    script.src = "https://cdn.joboco.io/widget/joboco_widget${suffix}.js";\n\
    script.onload = () => {\n\
      joboco.init({\n\
        botKey: "${this.props.agent.bot_key}",\n\
        apiKey: "${webClient.api_key}",\n\
        subdomain: "${webClient.subdomain}",\n\
      });\n\
    };\n\
    document.body.appendChild(script);\n\
  };\n\
  if (document.readyState !== "loading") {\n\
    eventHandler();\n\
  } else {\n\
    document.addEventListener("DOMContentLoaded", eventHandler, false);\n\
  }\n\
})();\n`;
    return html;
  };

  downloadScript = (() => {
    var content = this.htmlCode();
    var a = document.createElement('a');
    a.id = 'joboco__download-link';
    document.body.appendChild(a);
    a.style = 'display: none';
    return function (e) {
      const blob = new Blob([content], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'joboco_web.js';
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  render() {
    if (!this.props.integration.web_client.subdomain) {
      return <div>kintoneの設定が行われていません。</div>;
    }
    return (
      <>
        <div>
          JavaScriptファイルをダウンロードして、ご利用のcybozu.comサービスから読み込みを行なってください。
        </div>
        <div className="mt-3">
          <button type="button" className="btn" onClick={this.downloadScript}>
            <Icon name="file_download" />
            JavaScriptファイルのダウンロード
          </button>
        </div>
      </>
    );
  }
  componentDidMount() {}
}

export default WebClient;
