import { getFormatByKintoneField } from 'utils/get_format_by_kintone_field';
import { getOptionsByKintoneField } from 'utils/get_options_by_kintone_field';
import { isEqual } from 'utils/is_equal';
const getFixedSetFieldsFromKintoneFields = (
  scenarioFieldType,
  nowSetFields,
  gotFieldsFromKintone,
  getEmptyField,
) => {
  let changed = false;
  const resultFields = [];
  const lookupApps = {};
  gotFieldsFromKintone.forEach((field) => {
    if (field.lookup) {
      field.lookup.fieldMappings.forEach((related) => {
        lookupApps[related.field] = {
          app: field.lookup.relatedApp.app,
          field: related.relatedField,
        };
      });
    }
  });
  const getNewField = (nowField, kintoneField) => {
    const newField = { ...nowField };
    if (['select_fields'].indexOf(scenarioFieldType) >= 0) {
      newField.format =
        kintoneField.type === 'NUMBER'
          ? JSON.stringify(getFormatByKintoneField(kintoneField))
          : null;
    }
    if (['queries'].indexOf(scenarioFieldType) >= 0) {
      if (nowField.lookup_drill_down_app !== null) {
        const related = lookupApps[kintoneField.code];
        if (
          related === undefined ||
          related.app !== nowField.lookup_drill_down_app ||
          related.field !== nowField.lookup_drill_down_field
        ) {
          newField.lookup_drill_down_app = null;
          newField.lookup_drill_down_field = null;
        } else {
          newField.lookup_drill_down_app = related.app;
          newField.lookup_drill_down_field = related.field;
        }
      } else {
        newField.lookup_drill_down_app = null;
        newField.lookup_drill_down_field = null;
      }
    }
    if (['input_fields', 'update_fields'].indexOf(scenarioFieldType) >= 0) {
      if (kintoneField.lookup) {
        newField.lookup_app_id = kintoneField.lookup.relatedApp.app;
        newField.lookup_related_field = kintoneField.lookup.relatedKeyField;
        newField.lookup_search_by = kintoneField.lookup.filterCond;
        newField.lookup_order_by = kintoneField.lookup.sort;
        if (
          nowField.lookup_display_field === null ||
          kintoneField.lookup.lookupPickerFields.indexOf(
            nowField.lookup_display_field,
          ) < 0
        ) {
          newField.lookup_display_field = kintoneField.lookup.relatedKeyField;
        }
      } else {
        newField.lookup_app_id = null;
        newField.lookup_related_field = null;
        newField.lookup_search_by = null;
        newField.lookup_order_by = null;
      }
    }
    if (
      ['input_fields', 'query_fields', 'update_fields'].indexOf(
        scenarioFieldType,
      ) >= 0
    ) {
      newField.options = kintoneField.options
        ? JSON.stringify(getOptionsByKintoneField(kintoneField))
        : null;
    }
    newField.field_label = kintoneField.label;
    return newField;
  };
  nowSetFields.forEach((field) => {
    const kintoneField = gotFieldsFromKintone.find((gotField) => {
      return gotField.code === field.field_code;
    });
    let newField;
    if (kintoneField !== undefined) {
      newField = getNewField(field, kintoneField);
      if (!isEqual(field, newField)) {
        changed = true;
      }
      resultFields.push(newField);
    } else if (field.field_code !== '') {
      newField = field;
      changed = true;
      resultFields.push(newField);
    }
  });
  if (resultFields.length === 0) {
    resultFields.push(getEmptyField());
  }
  const res = {
    alertFlag: changed,
    changedFields: {
      [scenarioFieldType]: resultFields,
    },
  };
  return res;
};

export { getFixedSetFieldsFromKintoneFields };
