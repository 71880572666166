import reducer from 'components/integrations/reducers';
import App from 'components/integrations/container';
import renderer from 'controllers/react_renderer';

window.app.pages.IntegrationsIndex = function () {
  renderer.render(reducer, App, 'react-container', {
    agents: app.data.settings.agents,
    agent: app.data.settings.agent,
    integrations: app.data.integrations,
  });
};
