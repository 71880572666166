import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/webhook_notifications';
import Icon from 'components/base/icon';
import setClipboard from '../../utils/setClipboard';
import Modal from 'utils/modal';

class ListItem extends React.Component {
  onEditClick = (e) => {
    e.preventDefault();
    this.props.onClick(this.props.webhookNotification.id);
  };

  onDeleteClick = (e) => {
    e.preventDefault();
    Modal.confirm(
      '更新通知を削除します。この操作は元に戻せません。よろしいですか？',
      null,
      () => {
        this.props.onDelete(this.props.webhookNotification);
      },
    );
  };

  onDupClick = (e) => {
    e.preventDefault();
    this.props.onDup(this.props.webhookNotification.id);
  };

  render() {
    const webhookNotification = this.props.webhookNotification;
    return (
      <li className="list-group-item list-group-item-action">
        <div className="row">
          <div className="col">
            {' '}
            <a href="#!" onClick={this.onEditClick}>
              {webhookNotification.name}
            </a>
          </div>
          <div className="col">
            <a className="ml-4" href="#!" onClick={this.onEditClick}>
              <Icon name="edit" />
              編集
            </a>
            <a className="ml-4" href="#!" onClick={this.onDupClick}>
              <Icon name="content_copy" />
              複製
            </a>
            <a className="ml-4" href="#!" onClick={this.onDeleteClick}>
              <Icon name="delete" />
              削除
            </a>
          </div>
        </div>
      </li>
    );
  }
}

class Index extends React.Component {
  static propTypes = {
    agent: PropTypes.object.isRequired,
    webhookNotifications: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

  newWebhookNotification = (e) => {
    e.preventDefault();
    this.props.actions.newWebhookNotification(true);
  };

  setWebhookNotification = (id) => {
    this.props.actions.setWebhookNotification(id, true);
  };

  duplicateWebhookNotification = (id) => {
    this.props.actions.duplicateWebhookNotification(id);
  };

  deleteWebhookNotification = (id) => {
    this.props.actions.deleteWebhookNotification(id);
  };

  render() {
    const webhookUrl = `${app.botApiUrl}webhook_notifications?botkey=${this.props.agent.bot_key}`;
    return (
      <div className={styles.mainBody}>
        <h3>更新通知一覧</h3>
        <div className="form-group">
          <label>Webhook URL</label>
          <div className="my-1">
            kintoneのWebhookの設定でこちらのURLを設定してください
          </div>
          <div className="alert alert-info">
            <samp>{webhookUrl}</samp>
            <a
              href="#"
              title="コピー"
              onClick={(e) => {
                e.preventDefault();
                setClipboard(webhookUrl);
                Modal.toast('notice', 'コピーしました');
              }}
            >
              <i className="material-icons ml-2">content_copy</i>
            </a>
          </div>
        </div>
        <div>
          <a href="#!" onClick={this.newWebhookNotification}>
            <Icon name="add_circle_outline" />
            更新通知を作成する
          </a>
        </div>
        {this.props.webhookNotifications.length == 0 ? (
          <div className="mt-4 mb-4">更新通知はありません</div>
        ) : (
          <ul className="list-group mt-4 mb-4">
            {this.props.webhookNotifications.map((webhookNotification) => {
              return (
                <ListItem
                  key={webhookNotification.id}
                  webhookNotification={webhookNotification}
                  onClick={this.setWebhookNotification}
                  onDup={this.duplicateWebhookNotification}
                  onDelete={this.deleteWebhookNotification}
                />
              );
            })}
          </ul>
        )}
      </div>
    );
  }
  componentDidMount() {}
}

export default Index;
