import React from 'react';
import PropTypes from 'prop-types';
import setClipboard from '../../utils/setClipboard';
import Modal from '../../utils/modal';

class LineWorks extends React.Component {
  static propTypes = {
    agent: PropTypes.object.isRequired,
    integration: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    var intgr = this.props.integration;

    this.state = {
      errors: {},
    };

    intgr = intgr.line_works;
    this.state = $.extend(this.state, {
      api_ver: intgr.api_ver,
      id: intgr.id,
      bot_id: intgr.bot_id,
      api_id: intgr.api_id,
      consumer_key: intgr.consumer_key,
      access_token: intgr.access_token,
      client_id: intgr.client_id,
      service_account_id: intgr.service_account_id,
      client_secret: intgr.client_secret,
      private_key: intgr.private_key,
      bot_secret: intgr.bot_secret,
      private_key_is_saved: intgr.private_key === '******',
      fileName: '',
    });
    // V1.0は廃止されたため、2.0に強制変更
    if (this.state.api_ver === '1.0') {
      this.state.api_ver = '2.0';
    }
    this.private_key_input = React.createRef();
  }

  onPrivateKeyChange = () => {
    const reader = new FileReader();
    reader.readAsText(this.private_key_input.current.files[0]);
    reader.onload = () => {
      this.setState({
        fileName: this.private_key_input.current.files[0].name,
        private_key: reader.result,
      });
    };
  };

  onValueInputChange = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  onApiVerChange = (e) => {
    this.setState({
      api_ver: e.target[e.target.selectedIndex].value,
    });
  };

  onClickFileSelectButton = () => {
    const fileInput = document.getElementById('private_key');
    fileInput.click();
  };

  setAttributes = (integration) => {
    const state = this.state;
    const attributes = {
      id: state.id,
      bot_id: state.bot_id,
      api_ver: state.api_ver,
      api_id: state.api_id,
      consumer_key: state.consumer_key,
      access_token: state.access_token,
      client_id: state.client_id,
      service_account_id: state.service_account_id,
      client_secret: state.client_secret,
      private_key: state.private_key,
      bot_secret: state.bot_secret,
    };
    integration.line_works_integration_attributes = attributes;
  };

  apiV1Components = (isInvalid) => {
    const displayStyle = {
      display: this.state.api_ver === '1.0' ? 'block' : 'none',
    };
    return (
      <div style={displayStyle}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="api_id" className="required">
              API ID
            </label>
            <input
              type="text"
              className={`form-control ${isInvalid(
                'line_works_integration.api_id',
              )}`}
              id="api_id"
              value={this.state.api_id || ''}
              onChange={(e) => this.onValueInputChange(e, 'api_id')}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="consumer_key" className="required">
              Consumer Key
            </label>
            <input
              type="text"
              className={`form-control ${isInvalid(
                'line_works_integration.consumer_key',
              )}`}
              id="consumer_key"
              value={this.state.consumer_key || ''}
              onChange={(e) => this.onValueInputChange(e, 'consumer_key')}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="access_token" className="required">
              Access Token
            </label>
            <input
              type="text"
              className={`form-control ${isInvalid(
                'line_works_integration.access_token',
              )}`}
              id="access_token"
              value={this.state.access_token || ''}
              onChange={(e) => this.onValueInputChange(e, 'access_token')}
            />
          </div>
        </div>
      </div>
    );
  };

  apiV2Components = (isInvalid) => {
    const displayStyle = {
      display: this.state.api_ver === '2.0' ? 'block' : 'none',
    };
    return (
      <div>
        <div style={displayStyle}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="bot_secret" className="required">
                Bot Secret
              </label>
              <input
                type="text"
                className={`form-control ${isInvalid(
                  'line_works_integration.bot_secret',
                )}`}
                id="bot_secret"
                value={this.state.bot_secret || ''}
                onChange={(e) => this.onValueInputChange(e, 'bot_secret')}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="client_id" className="required">
                Client ID
              </label>
              <input
                type="text"
                className={`form-control ${isInvalid(
                  'line_works_integration.client_id',
                )}`}
                id="client_id"
                value={this.state.client_id || ''}
                onChange={(e) => this.onValueInputChange(e, 'client_id')}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="client_secret" className="required">
                Client Secret
              </label>
              <input
                type="text"
                className={`form-control ${isInvalid(
                  'line_works_integration.client_secret',
                )}`}
                id="client_secret"
                value={this.state.client_secret || ''}
                onChange={(e) => this.onValueInputChange(e, 'client_secret')}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="service_account_id" className="required">
                Service Account ID
              </label>
              <input
                type="text"
                className={`form-control ${isInvalid(
                  'line_works_integration.service_account_id',
                )}`}
                id="service_account_id"
                value={this.state.service_account_id || ''}
                onChange={(e) =>
                  this.onValueInputChange(e, 'service_account_id')
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="private_key" className="required">
                Private Key
                {this.state.private_key_is_saved ? '(登録済み)' : ''}
              </label>
              <div className="py-2">
                <button
                  type="button"
                  className="btn btn-no-ballon"
                  onClick={this.onClickFileSelectButton}
                >
                  ファイルを選択
                </button>
                <span className="ml-2 small">{this.state.fileName}</span>
                <input
                  type="file"
                  id="private_key"
                  ref={this.private_key_input}
                  onChange={this.onPrivateKeyChange}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const isInvalid = (name) => {
      if (
        this.props.integration.errors &&
        this.props.integration.errors[name]
      ) {
        return 'is-invalid';
      }
      return '';
    };
    const callbackUrl = `${app.botApiUrl}lineworks?botkey=${this.props.agent.bot_key}`;
    return (
      <>
        <div className="form-group">
          <label>コールバックURL</label>
          <div className="alert alert-info">
            <samp>{callbackUrl}</samp>
            <a
              href="#"
              title="コピー"
              onClick={(e) => {
                e.preventDefault();
                setClipboard(callbackUrl);
                Modal.toast('notice', 'コピーしました');
              }}
            >
              <i className="material-icons ml-2">content_copy</i>
            </a>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="bot_id" className="required">
              Bot ID
            </label>
            <input
              type="text"
              className={`form-control ${isInvalid(
                'line_works_integration.bot_id',
              )}`}
              id="bot_id"
              value={this.state.bot_id || ''}
              onChange={(e) => this.onValueInputChange(e, 'bot_id')}
            />
          </div>
        </div>
        {this.apiV2Components(isInvalid)}
      </>
    );
  }
  componentDidMount() {}
}

export default LineWorks;
