import React from 'react';
import ReactDOM from 'react-dom';

export const createDialog = (component, props, className) => {
  if ($('.modal-backdrop').is(':visible')) {
    return;
  }

  var myClass = 'modal fade';
  if (className) myClass += ' ' + className;
  var $node = $('<div />', { class: myClass }).appendTo('body').hide();

  ReactDOM.render(React.createElement(component, props), $node.get(0));
};

export const wrapper = (WrappedComponent) => {
  class Dialog extends React.Component {
    constructor(props) {
      super(props);
      this.refSelf = React.createRef();
      this.btnCancel = React.createRef();
      this.btnOk = React.createRef();
      this.wrapped = React.createRef();
    }

    render() {
      return (
        <div ref={this.refSelf} className="modal-dialog">
          <WrappedComponent
            {...this.props}
            ref={this.wrapped}
            close={this.close}
            modalHeader={this.modalHeader}
            modalFooter={this.modalFooter}
          />
        </div>
      );
    }

    modalHeader = (opts) => {
      if (!opts) opts = {};
      return (
        <div className="modal-header">
          <h5 className="modal-title text-nowrap">{opts.title || ''}</h5>
          <button
            type="button"
            className="close"
            onClick={this.close}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    };

    modalFooter = (opts) => {
      if (!opts) opts = {};
      var className = 'btn btn-primary';
      if (opts.disabled) {
        className += ' disabled';
      }
      return (
        <div className="modal-footer">
          <button
            type="button"
            ref={this.btnCancel}
            className="btn btn-default"
            onClick={opts.onCancel || this.close}
          >
            {opts.cancelCaption || 'キャンセル'}
          </button>
          <button
            type="button"
            ref={this.btnOk}
            className={className}
            onClick={opts.onOk}
          >
            {opts.okCaption || 'OK'}
          </button>
        </div>
      );
    };

    _getRootNode = () => {
      return $(this.refSelf.current).parent();
    };

    open = () => {
      var opts = $.extend({ backdrop: 'static' }, this.props.opts);

      this._getRootNode().modal(opts);
    };

    close = () => {
      this._getRootNode().modal('hide');

      // this._onClosed();
    };

    _onClosed = () => {
      ReactDOM.unmountComponentAtNode(this._getRootNode().get(0));
    };

    componentDidMount() {
      var self = this;
      var wrapped = this.wrapped.current;

      this._getRootNode().on('hidden.bs.modal', function (e) {
        self._onClosed();
        if (self.props.opts && self.props.opts.fixed) {
          $('body').removeClass('modal-open-fixed');
          $('body').css({
            top: '',
          });
          if (self.scrollPos) $(window).scrollTop(self.scrollPos);

          if (wrapped.onModalHidden) {
            wrapped.onModalHidden();
          }
        }
      });
      this._getRootNode().on('shown.bs.modal', function () {
        if (self.btnCancel.current) {
          $(self.btnCancel.current).focus();
        }
        if (wrapped.onModalShown) {
          wrapped.onModalShown();
        }
      });
      this.open();
    }

    componentWillUnmount() {
      var node = this._getRootNode();
      document.body.removeChild(node.get(0));
    }
  }

  return Dialog;
};
